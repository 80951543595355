import { ICourseResponse, TProcessData } from '../types/Mentor';
import { axiosAuth, axiosCatch, docApi } from '../../utils/AxiosHelper';
import { AxiosResponse } from 'axios';
import {
  AddCollectionNote,
  DeleteCollectionNote,
  ICollectionNote,
  IResponseAddCollectionNote,
  IResponseDeleteCollectionNote,
  UpdateCollectionNote
} from '../types/User';

export const reqGetStdCourse = async (params: any = {}): Promise<AxiosResponse<ICourseResponse>> => {
  return await axiosAuth().get('/student/course/list', { params }).catch(axiosCatch);
};
export const reqGetStdCourseInfo = async (params: any = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/student/course/info', { params }).catch(axiosCatch);
};
export const reqEnrollStdRegister = async (params: any = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth().post('/student/course/enroll', params).catch(axiosCatch);
};
export const reqGetArticle = async (docId: string): Promise<AxiosResponse<any>> => {
  return await docApi.post('/api/documents.info', { id: docId }).catch(axiosCatch);
};
export const reqUpdateProcess = async (data: TProcessData): Promise<AxiosResponse<any>> => {
  return await axiosAuth().post('/student/lesson/progress', data).catch(axiosCatch);
};
export const reqGetNotesCollection = async (): Promise<AxiosResponse<ICollectionNote>> => {
  return await axiosAuth().get('/student/collection-note/list').catch(axiosCatch);
};
export const reqAddNotesCollection = async (title: AddCollectionNote): Promise<AxiosResponse<IResponseAddCollectionNote>> => {
  return await axiosAuth().post('/student/collection-note/create', title).catch(axiosCatch);
};
export const reqDeleteNotesCollection = async (collectionNoteId: DeleteCollectionNote): Promise<AxiosResponse<IResponseDeleteCollectionNote>> => {
  return await axiosAuth().post('/student/collection-note/delete', collectionNoteId).catch(axiosCatch);
};
export const reqUpdateNotesCollection = async (payload: UpdateCollectionNote): Promise<AxiosResponse<{}>> => {
  return await axiosAuth().post('/student/collection-note/update', payload).catch(axiosCatch);
};

export const reqSaveMessage = async (message: any) => {
  return await axiosAuth().post('/gpt/save-message', message).catch(axiosCatch);
};
export const reqGetMessages = async (params: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/gpt/messages', { params }).catch(axiosCatch);
};
export const reqGetHistories = async (params: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/gpt/histories', { params }).catch(axiosCatch);
};
