const ChatTyping = () => {
  return <div className='chat'>
    <div className='chat typing'>
      <div className='dot'></div>
      <div className='dot'></div>
      <div className='dot'></div>
    </div>
  </div>;
};
export default ChatTyping;
