import React, { FC, useCallback, useState } from 'react';
import { List } from 'antd';
import { nanoid } from 'nanoid';

interface TProps {
  histories: any;
  onClick: (messageId: any) => void;
}

const CHAT_ID = localStorage.getItem('CHAT_ID') ?? nanoid();

const ChatHistory: FC<TProps> = ({ histories = [], onClick }) => {
  const [active, setActive] = useState<string>(CHAT_ID);
  const setThread = useCallback((item: any) => {
    setActive(item.message_id);
    localStorage.setItem('CHAT_ID', item.message_id);
    onClick(item.message_id);
  }, []);

  return <>
    <List
      dataSource={histories}
      renderItem={(item: any) => {
        return <List.Item
          onClick={() => setThread(item)}
          className={`${active === item.message_id && 'bg-slate-200'} cursor-pointer hover:bg-slate-200`}
          key={item.message_id}>
          <span className='px-3 text-ellipsis overflow-hidden ...'>{item.content}</span>
        </List.Item>;
      }}
    />
  </>;
};
export default ChatHistory;
