import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import './theme.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';


ReactDOM.render( // 👈️ deprecated starting React 18
  <BrowserRouter>
    <ConfigProvider theme={{
      components: {
        Layout: {
          colorBgBody: '#FBFAF4',
        },
        Button: {
          colorPrimary: '#2F9ABC',
          colorError: '#DD5656'
        }
      }
    }}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root'),

);

