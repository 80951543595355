import { ActionType, COMMON } from '../actions/actionTypes';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { COMMON_GET_MENU } from '../actions/types';
import { reqGetMenu } from '../services/common';
import { IMenuRoute } from '../contants/routeRole';
import { message } from 'antd';

function* getAuthMenu({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: IMenuRoute[] = yield call(reqGetMenu, payload.typeLogin);
    const newResponse = response.filter((itemResponse: any) => {
      return payload.userPermission.find((itemPayload: any) => itemPayload === itemResponse.key);
    });
    const data: IMenuRoute[] = [
      {
        title: 'Document notes',
        key: 'document-notes',
        path: '/document-notes',
        icon: 'list-check',
        items: [
          {
            title: 'Bộ notes kĩ năng Làm việc Cơ bản',
            key: 'collection-notes-1',
            path: '/collection-notes',
            icon: 'list-check',
            id: '1',
            items: [
              {
                title: 'Notes Kỹ năng quản lý thời gian',
                key: 'group-notes-1',
                path: '/group-notes',
                icon: 'list-check',
                id: '1-1',
                items: [
                  {
                    title: 'Notes 90% nghèo vì không quản lý được thời gian',
                    key: 'notes-1',
                    path: '/notes',
                    icon: 'list-check',
                    id: '1-1-1',
                  },
                  {
                    title: 'Notes 5 bí quyết quản lý thời gian',
                    key: 'notes-2',
                    path: '/notes',
                    icon: 'list-check',
                    id: '1-1-2',
                  },
                ],
              },
              {
                title: 'Notes Kỹ năng văn phòng',
                key: 'group-notes-2',
                path: '/group-notes',
                icon: 'list-check',
                id: '1-2',
              },
            ],
          },
          {
            title: 'Bộ notes Javascript Cơ bản',
            key: 'collection-notes-2',
            path: '/collection-notes',
            icon: 'list-check',
            id: '2',
          },
        ],
      },
    ];    
    newResponse.push(...data);
    yield put(COMMON.SET_MENU(newResponse));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(COMMON_GET_MENU, getAuthMenu)
  ]);
}
