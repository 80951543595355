import { ActionType, AUTH, COMMON } from '../actions/actionTypes';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_RENEW_TOKEN } from '../actions/types';
import { message, Modal } from 'antd';
import { reqAuthLogin, reqAuthRenewToken } from '../services/auth';
import { history } from '../store';
import { ApiResponse } from '../../utils/AxiosHelper';
import { AuthLoginResponse, ReFreshTokenResponse } from '../types/User';
import { IRoute, publicRoutes } from '../route/routers';
import { isLoginPage } from '../../utils/hepler';
import { nanoid } from 'nanoid';

function* onLogin({ payload }: ActionType) {
  try {
    const CHAT_ID = nanoid();
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<AuthLoginResponse> = yield call(reqAuthLogin, payload);
    const { roles, tokens, typeLogin, id } = response.data.data;
    const role = roles.filter((r: any) => r.isEnable).map((r: any) => r.path ? r.path : ['/']);
    const userPermission = roles.filter((r: any) => r.isEnable).map((r: any) => r.slug);
    let publicPermission = publicRoutes[typeLogin]?.map((route: IRoute) => route.key);
    const mapPermission = [...userPermission, ...publicPermission ?? []];

    localStorage.setItem('CHAT_ID', CHAT_ID);
    localStorage.setItem('auth', tokens.accessToken);
    localStorage.setItem('refreshToken', tokens.refreshToken);
    localStorage.setItem('typeLogin', typeLogin);
    localStorage.setItem('roles', JSON.stringify(role));
    localStorage.setItem('userPermission', JSON.stringify(mapPermission));
    localStorage.setItem('id', id?.toString()!);

    yield put(COMMON.GET_MENU(typeLogin, mapPermission));
    yield put(AUTH.LOGIN_SUCCESS({
      roles: role,
      typeLogin: typeLogin,
      token: tokens.accessToken,
      name: response.data.data.name,
      chatId: CHAT_ID
    }));
  } catch (e: any) {
    yield put(COMMON.LOADING(false));
    message.error(e.message);
  }
}

function* renewToken() {
  const typeLogin = localStorage.getItem('typeLogin');

  try {
    if (history.location.pathname.match(/login/i)) {
      return;
    }
    const refToken = localStorage.getItem('refreshToken');
    if (!refToken) {
      yield put(AUTH.LOGOUT());
    }
    const response: ApiResponse<ReFreshTokenResponse> = yield call(reqAuthRenewToken, { refreshToken: refToken });
    const { accessToken, refreshToken } = response.data.data;
    yield put(AUTH.LOGIN_SUCCESS({ token: accessToken, refreshToken }));
    localStorage.setItem('auth', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  } catch (e: any) {
    yield put(COMMON.LOADING(false));
    message.error(e.message);
    const path = typeLogin == 'admin' ? '/login-admin' : typeLogin === 'mentor' ? '/login-mentor' : '/login';
    if (e.code === 401) {
      Modal.confirm({
        title: 'Thông báo!',
        cancelButtonProps: { style: { display: 'none' } },
        content: 'Bạn đã hết phiên đăng nhập!. Vui lòng đăng nhập lại',
        onOk: () => window.location.replace(path)
      });
    }
  }
}

function* onLogout({ payload }: ActionType) {
  try {

    // @ts-ignore
    Modal.confirm({
      title: 'Đăng xuất hệ thống',
      onOk: () => {
        const typeLogin = localStorage.getItem('typeLogin');
        const path = typeLogin == 'admin' ? '/login-admin' : typeLogin === 'mentor' ? '/login-mentor' : '/login';
        localStorage.removeItem('auth');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('typeLogin');
        localStorage.removeItem('roles');
        localStorage.removeItem('userPermission');
        localStorage.removeItem('id');
        localStorage.removeItem('CHAT_ID');
        window.location.replace(path);
      }
    });

  } catch (e: any) {
    message.error(e.message);
  }
}

function* checkToken(): any {
  try {
    if (isLoginPage()) {
      return false;
    }
    const _token = localStorage.getItem('auth');
    const typeLogin = localStorage.getItem('typeLogin');
    const roles = JSON.parse((localStorage.getItem('roles')!));
    const refreshToken = localStorage.getItem('refreshToken');
    const userPermission = JSON.parse(localStorage.getItem('userPermission')!);
    if (!_token || !typeLogin || !roles || !refreshToken) {
      yield put(AUTH.LOGOUT());
      return;
    }
    yield put(COMMON.GET_MENU(typeLogin, userPermission));

  } catch (e: any) {
    message.error(e.message);
    yield put(AUTH.LOGOUT());
  }
}


function* onLoginUser() {
  yield takeLatest(AUTH_LOGIN, onLogin);
}

function* onLogoutUser() {
  yield takeLatest(AUTH_LOGOUT, onLogout);
}

function* onRenewToken() {
  // yield takeEvery(AUTH_RENEW_TOKEN, renewToken);
}


export default function* rootSaga() {
  yield all([
    fork(onLoginUser),
    fork(onLogoutUser),
    //fork(onRenewToken),
    checkToken()
  ]);
}
