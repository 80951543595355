import { BookFilled, HomeOutlined, LayoutFilled, UserOutlined } from '@ant-design/icons';
import { IconName } from '@fortawesome/fontawesome-svg-core';

export interface IMenuRoute {
  title: string,
  path: string,
  key: string,
  icon?: IconName,
  items?: IMenuRoute[],
  fullWith?: boolean,
  hide?: boolean,
  id?:string
}

export const MentorRoute: IMenuRoute[] = [
  {
    title: 'Overview',
    key: 'mentor-overview',
    path: '/',
    icon: 'chart-simple'
  },
  {
    title: 'Course Management',
    key: 'course-management',
    path: '/course-management',
    icon: 'book'
  },
  {
    title: 'Quiz Management',
    key: 'quiz',
    path: '/quiz',
    icon: 'list-check'
  },
  // {
  //   title: 'Chat with Mentee',
  //   key: 'chat',
  //   path: '',
  //   icon: BookFilled,
  //   items: [
  //     { title: 'Chờ phê duyệt', key: 'mentee.chat.pending', path: '/mentee-chat' },
  //     { title: 'Đang diễn ra', key: 'mentee.chat.processing', path: '/mentee-chat/processing' }
  //   ]
  // },
  // {
  //   title: 'Interview',
  //   key: 'interview',
  //   path: '/interview',
  //   icon: HomeOutlined
  // },
  // {
  //   title: 'Support Management',
  //   key: 'support-management',
  //   path: '/support-management',
  //   icon: HomeOutlined
  // },
  {
    title: 'Schedule register',
    key: 'schedule-register',
    path: '/schedule-register',
    icon: 'clipboard-list'
  },
  // {
  //   title: 'Settings',
  //   key: 'settings',
  //   path: '/settings',
  //   icon: HomeOutlined
  // }
];
export const AdminRoute: IMenuRoute[]  = [
  {
    title: 'Overview',
    key: 'management-overview-student',
    path: '/',
    icon: 'chart-simple'
  },
  {
    title: 'Sinh viên',
    key: 'management-student',
    path: '/student',
    icon: 'people-roof',
    items: [
      {
        title: 'Quản lý tài khoản',
        key: 'student.list-account',
        path: '/list-student',
        icon: 'arrows-down-to-people'
      },
      // {
      //   title: 'Quản lý lộ trình',
      //   key: 'student.manage_path',
      //   path: '/',
      //   icon: UserOutlined
      // }
    ]
  },
  {
    title: 'Quản lý mentor',
    key: 'management-mentor',
    path: '/',
    icon: 'people-roof',
    items: [
      {
        title: 'Danh sách Mentor',
        key: 'mentor.list-account',
        path: '/list-mentor',
        icon: 'list-ol'
      },
      // {
      //   title: 'Thống kê phiên support',
      //   key: 'mentor.session_statistics',
      //   path: '/',
      //   icon: UserOutlined
      // },
      // {
      //   title: 'Thống kê phiên chấm thi',
      //   key: 'mentor.exam_session',
      //   path: '/',
      //   icon: UserOutlined
      // }
    ]
  },
  {
    title: 'Quản lý Admin',
    key: 'management-admin',
    path: '/list-admin',
    icon: 'people-group',
  },
  // {
  //   title: 'Quản lý thi',
  //   key: 'management-exam',
  //   path: '/',
  //   icon: UserOutlined,
  //   items: [
  //     {
  //       title: 'Phê duyệt lịch thi',
  //       key: 'exam.approve_schedule',
  //       path: '/',
  //       icon: UserOutlined
  //     },
  //     {
  //       title: 'Danh sách thi',
  //       key: 'exam.list',
  //       path: '/',
  //       icon: UserOutlined
  //     }
  //   ]
  // },
  {
    title: 'Quản lý giáo trình',
    key: 'management-course',
    path: '/course',
    icon: 'book-open-reader'
  },
  {
    title: 'Quản lý Quiz',
    key: 'management-quiz',
    path: '/management-quiz',
    icon: 'magnifying-glass-chart'
  },
];
export const StudentRoute: IMenuRoute[] = [
  {
    title: 'Overview',
    key: 'student-overview',
    path: '/',
    icon: 'chart-simple'
  },
  // {
  //   title: 'Career Path',
  //   key: 'student-career-path',
  //   path: '/career-path',
  //   icon: LayoutFilled
  // },
  {
    title: 'Course',
    key: 'student-course',
    path: '/course',
    icon: 'book-open'
  },
  {
    title: 'My notes',
    key: 'student-notes',
    path: '/my-notes',
    icon: 'list-check'
  },
  // {
  //   title: 'Practice online',
  //   key: 'student-practice-online',
  //   path: '/practice-online',
  //   icon: 'code'
  // },
  // {
  //   title: 'Chat with Mentor',
  //   key: 'student-chat-mentor',
  //   path: '/chat-with-mentor',
  //   icon: LayoutFilled
  // },
  // {
  //   title: 'Interview',
  //   key: 'student-interview',
  //   path: '/interview',
  //   icon: LayoutFilled
  // },
  // {
  //   title: 'Settings',
  //   key: 'student-settings',
  //   path: '/settings',
  //   icon: LayoutFilled
  // },
  {
    title: 'Course view',
    key: 'student-course-view',
    path: '/course/:courseId',
    fullWith: false,
    hide: true,
    icon: 'chalkboard'

  },
  {
    title: 'Course article',
    key: 'student-course-article',
    path: '/article/:courseId/:subjectId',
    fullWith: true,
    hide: true,
    icon: 'newspaper'
  }
];
