import {
  COURSE_ADD, COURSE_ADD_SUBJECT_CACHE_SUCCESS, COURSE_DELETE,
  COURSE_STATE
} from '../actions/types';
import { ECourseType, ILessonGroupItem, ILessonItem, ISubjectItem } from '../types/Mentor';

export interface ICourseState {
  locale: string;
  loading: boolean;
  acLoad: boolean;
  error: boolean,
  actionLoad?: boolean,
  basicList: any;
  allList: any;
  subject?: ISubjectItem | null,
  group?: ILessonGroupItem | null,
  lesson?: ILessonItem
  courseDetail: any;
  lessonGroup: any;
  specializedList: any,
}

const initialState = {
  locale: 'vi',
  loading: false,
  acLoad: false,
  error: false,
  basicList: [],
  subject: null,
  group: null,
  courseDetail: null,
  lessonGroup: null,
  specializedList: [],
  allList: []
};
export const courseReducer = (state = initialState, action: any): ICourseState => {
  switch (action.type) {
    case COURSE_ADD_SUBJECT_CACHE_SUCCESS: {
      // @ts-ignore
      const detail = { ...state.courseDetail, listSubject: [...state.courseDetail.listSubject, action.payload] };
      return { ...state, courseDetail: detail };
    }
    case COURSE_STATE:
      return { ...state, ...action.payload, error: false, loading: false, actionLoad: false };
    default:
      return { ...state };
  }
};
export default courseReducer;
