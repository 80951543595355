import { Button, List, Modal, Space, Tabs, message } from "antd";
import { TrashIcon } from "outline-icons";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { STUDENT } from "src/redux/actions/actionTypes";
import { AppState } from "src/redux/reducers";
import { IPersonalNoteList } from "src/redux/types/User";
import NotesPersonal from "./component/NotesPersonal";
import { reqDeletePersonalNotes } from "src/redux/services/notes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabsProps } from "antd/lib";
import InjectMessage from "src/utils/InjectMessage";

const PersonalNotes = () => {
    const [listNotes, setListNotes] = useState<IPersonalNoteList>()
    const history = useHistory();
    const { personalNotes }: { personalNotes: IPersonalNoteList } = useSelector((app: AppState) => app.student);
    const { id }: any = useParams()
    const dispatch = useDispatch();

    console.log('personal notes', listNotes);
    console.log('id', id);
    const renderNotesPersonal = useCallback(() => {
        console.log('renderNotesPersonal', id);
        return (
            <div>
            <Button type='default' onClick={() => history.goBack()}>
                <InjectMessage id='admin.shared.go_back' />
            </Button>
            <NotesPersonal
                id={id}
                key={id + Math.random()}
            />
            </div>
        );
    }, [id]);
    const onDelete = (personalNoteId: string) => {
        console.log(personalNoteId);
        Modal.confirm({
            title: `Xác nhận xoá ghi chú`,
            content: `Ghi chú sẽ không khôi phục được.Bạn có chắc chắn muốn xoá ghi chú này?`,
            onOk: () => {
                reqDeletePersonalNotes({ personalNoteId: personalNoteId }).then(() => {
                    message.success('Xóa thành công');
                    dispatch(STUDENT.GET_PERSONAL_NOTES());
                }
                )
            }
        })
    }
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Document`,
            children: personalNotes && <List

                dataSource={personalNotes}
                renderItem={(item) => <div
                    style={{
                        backgroundColor: 'white',
                        borderRadius: 8,
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                        padding: 12,
                        margin: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '80%',
                    }}
                    onClick={() => {
                        history.push(`/personal-notes/${item.id}`);
                    }}
                >
                    <div style={{ width: '80%' }}>
                        <FontAwesomeIcon fontSize={16} style={{ marginRight: 8 }} icon={['fas', 'file']} />
                        <span style={{ marginRight: 8, fontSize: 16 }}>{item.title}</span></div>
                    <Space>
                        <TrashIcon onClick={(e) => {
                            e.stopPropagation();
                            onDelete(item.id);
                        }}
                        />
                    </Space>

                </div>
                }
            />
        }
    ];
    return <div>
        {id == 'overview' ? <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Ghi chú cá nhân</h1>
                <Button type="primary" onClick={() => history.push(
                    `/personal-notes/new`
                )}>Tạo notes</Button>
            </div>

            <Tabs defaultActiveKey="1" items={items} />
        </div> : id == 'new' ? <div>
            <Button type='default' onClick={() => history.goBack()}>
                <InjectMessage id='admin.shared.go_back' />
            </Button>
            <NotesPersonal key={'new' + Math.random()} />
        </div> :
            renderNotesPersonal()
        }
    </div>
}

export default PersonalNotes;