export const COMMON_SET_STATE = 'COMMON/SET_STATE';
export const COMMON_GET_MENU = 'COMMON/GET_MENU';
export const AUTH_LOGIN = 'AUTH/LOGIN';
export const AUTH_LOGOUT = 'AUTH/LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS';
export const AUTH_LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
export const AUTH_RENEW_TOKEN = 'AUTH/RENEW_TOKEN';
export const AUTH_RENEW_TOKEN_SUCCESS = 'AUTH/RENEW_TOKEN_SUCCESS';
export const AUTH_GET_PERMISSION = 'AUTH/AUTH_GET_PERMISSION';
export const AUTH_GET_PERMISSION_SUCCESS = 'AUTH/AUTH_GET_PERMISSION_SUCCESS';

//ADMIN

export const STUDENT_STATE = 'ADMIN/STUDENT_STATE';
export const GET_LIST_STUDENT = 'ADMIN/GET_LIST_STUDENT';
export const GET_LIST_STUDENT_SUCCESS = 'ADMIN/GET_LIST_STUDENT_SUCCESS';
export const DETAIL_STUDENT = 'STUDENT/DETAIL_STUDENT';
export const DETAIL_STUDENT_SUCCESS = 'STUDENT/DETAIL_STUDENT_SUCCESS';
export const CREATE_STUDENT = 'ADMIN/CREATE_STUDENT';
export const CREATE_STUDENT_SUCCESS = 'ADMIN/CREATE_STUDENT_SUCCESS';
export const EDIT_STUDENT = 'ADMIN/EDIT_STUDENT';
export const EDIT_STUDENT_SUCCESS = 'ADMIN/EDIT_STUDENT_SUCCESS';
export const DELETE_STUDENT = 'ADMIN/DELETE_STUDENT';
export const DELETE_STUDENT_SUCCESS = 'ADMIN/DELETE_STUDENT_SUCCESS';

export const ADMIN_STATE = 'ADMIN/ADMIN_STATE';
export const GET_LIST_ADMIN = 'ADMIN/GET_LIST_ADMIN';
export const GET_LIST_ADMIN_SUCCESS = 'ADMIN/GET_LIST_ADMIN_SUCCESS';
export const DETAIL_ADMIN = 'ADMIN/DETAIL_ADMIN';
export const DETAIL_ADMIN_SUCCESS = 'ADMIN/DETAIL_ADMIN_SUCCESS';
export const CREATE_ADMIN = 'ADMIN/CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'ADMIN/CREATE_ADMIN_SUCCESS';
export const DELETE_ADMIN = 'ADMIN/DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'ADMIN/DELETE_ADMIN_SUCCESS';
export const EDIT_ADMIN = 'ADMIN/EDIT_ADMIN';
export const EDIT_ADMIN_SUCCESS = 'ADMIN/EDIT_ADMIN_SUCCESS';

export const MENTOR_STATE = 'MENTOR/MENTOR_STATE';
export const GET_LIST_MENTOR = 'MENTOR/GET_LIST_MENTOR';
export const GET_LIST_MENTOR_SUCCESS = 'MENTOR/GET_LIST_MENTOR_SUCCESS';
export const CREATE_MENTOR = 'MENTOR/CREATE_MENTOR';
export const CREATE_MENTOR_SUCCESS = 'MENTOR/CREATE_MENTOR_SUCCESS';
export const DELETE_MENTOR = 'MENTOR/DELETE_MENTOR';
export const DELETE_MENTOR_SUCCESS = 'MENTOR/DELETE_MENTOR_SUCCESS';
export const EDIT_MENTOR = 'MENTOR/EDIT_MENTOR';
export const EDIT_MENTOR_SUCCESS = 'MENTOR/EDIT_MENTOR_SUCCESS';
export const DETAIL_MENTOR = 'MENTOR/DETAIL_MENTOR';
export const DETAIL_MENTOR_SUCCESS = 'MENTOR/DETAIL_MENTOR_SUCCESS';
// MENTOR
export const MENTOR_STATE_SUCCESS = 'MENTOR/MENTOR_STATE_SUCCESS';
export const CHAT_LIST_GET = 'MENTOR/CHAT_LIST';
export const CHAT_LIST_APPROVE = 'MENTOR/CHAT_LIST_APPROVE';
export const CHAT_LIST_APPROVE_SUCCESS = 'MENTOR/CHAT_LIST_APPROVE_SUCCESS';
export const CHAT_LIST_REJECT = 'MENTOR/CHAT_LIST_REJECT';
export const CHAT_LIST_REJECT_SUCCESS = 'MENTOR/CHAT_LIST_REJECT_SUCCESS';

export const TEST_LIST_GET = 'MENTOR/TEST_LIST_GET';
export const CHAT_LIST_JOIN = 'MENTOR/CHAT_LIST_JOIN';
export const CHAT_LIST_JOIN_SUCCESS = 'MENTOR/CHAT_LIST_JOIN_SUCCESS';

export const SUPPORT_STATISTIC_GET = 'MENTOR/SUPPORT_STATISTIC_GET';

export const WORK_SCHEDULE_GET = 'MENTOR/WORK_SCHEDULE_GET';
export const WORK_SCHEDULE_ADD = 'MENTOR/WORK_SCHEDULE_ADD';
export const WORK_SCHEDULE_REMOVE = 'MENTOR/WORK_SCHEDULE_REMOVE';
export const WORK_SCHEDULE_REMOVE_SUCCESS = 'MENTOR/WORK_SCHEDULE_REMOVE_SUCCESS';
export const WORK_SCHEDULE_UPDATE = 'MENTOR/WORK_SCHEDULE_UPDATE';
export const WORK_SCHEDULE_UPDATE_SUCCESS = 'MENTOR/WORK_SCHEDULE_REMOVE_SUCCESS';


export const COURSE_STATE = 'COURSE/COURSE_STATE';
export const COURSE_GET_LIST = 'COURSE/GET_LIST';
export const COURSE_GET_DETAIL = 'COURSE/GET_DETAIL';
export const COURSE_GET_SUBJECT = 'COURSE/GET_SUBJECT';
export const COURSE_GROUP_DETAIL = 'COURSE/GET_GROUP_DETAIL';
export const COURSE_ADD = 'COURSE/ADD';
export const COURSE_ADD_SUBJECT = 'COURSE/ADD_SUBJECT';
export const COURSE_ADD_SUBJECT_CACHE = 'COURSE/ADD_SUBJECT_CACHE';
export const COURSE_ADD_SUBJECT_CACHE_SUCCESS = 'COURSE/ADD_SUBJECT_CACHE_SUCCESS';
export const COURSE_UPDATE_SUBJECT = 'COURSE/UPDATE_SUBJECT';
export const COURSE_DELETE_SUBJECT = 'COURSE/DELETE_SUBJECT';
export const COURSE_ADD_LESSON = 'COURSE/ADD_LESSON';
export const COURSE_DEL_LESSON = 'COURSE/DEL_LESSON';
export const COURSE_UPDATE = 'COURSE/UPDATE';
export const COURSE_UPDATE_GROUP = 'COURSE/UPDATE_GROUP';
export const COURSE_DEL_GROUP = 'COURSE/DEL_GROUP';
export const COURSE_ADD_GROUP = 'COURSE/ADD_GROUP';
export const COURSE_UPDATE_LESSON = 'COURSE/UPDATE_LESSON';
export const COURSE_DELETE = 'COURSE/DELETE';

export const STD_STATE = 'STD/STATE';
export const STD_COURSE_LIST = 'STD/COURSE_LIST';
export const STD_UPDATE_PROCESS = 'STD/UPDATE_PROCESS';
export const STD_COURSE_INFO = 'STD/COURSE_INFO';
export const STD_ENROLL_LIST = 'STD/ENROLL_LIST';
export const STD_ENROLL = 'STD/ENROLL';
export const STD_GET_ARTICLE = 'STD/GET_ARTICLE';
export const STD_ENROLL_SUCCESS = 'STD/ENROLL_SUCCESS';

export const GPT_GET_CONVERSATIONS = 'GPT/GET_CONVERSATIONS';
export const GPT_GET_MESSAGES = 'GPT/GET_MSG';
export const GPT_GET_HISTORIES = 'GPT/GET_HISTORIES';
export const GPT_SAVE_MESSAGE = 'GPT/SAVE_MSG';


export const QUIZ_GET_LIST = 'QUIZ/GET_LIST';
export const QUIZ_GET_QES = 'QUIZ/GET_QES';
export const QUIZ_STATE = 'QUIZ/STATE';

export const ADM_QUIZ_GET_LIST = 'QUIZ/GET_LIST';
export const QUIZ_COLLECTION_LIST = 'COLLECTION_QUIZ/GET_LIST';
export const QUIZ_COLLECTION_CREATE = 'COLLECTION_QUIZ/CREATE';
export const QUIZ_COLLECTION_UPDATE = 'COLLECTION_QUIZ/UPDATE';
export const QUIZ_COLLECTION_DELETE = 'COLLECTION_QUIZ/DELETE';
export const QUIZ_COLLECTION_INFO = 'COLLECTION_QUIZ/INFO';

export const QUIZ_CREATE = 'QUIZ/CREATE'
export const QUIZ_UPDATE = 'QUIZ/UPDATE'
export const QUIZ_DELETE = 'QUIZ/DELETE'
export const QUIZ_INFO = 'QUIZ/INFO'

export const ADM_QUIZ_GET_QES = 'QUIZ/GET_QES';
export const ADM_QUIZ_STATE = 'QUIZ/STATE';

export const NOTES_GET_COLLECTION = 'NOTES/GET_COLLECTION';
export const NOTES_GET_COLLECTION_SUCCESS = 'NOTES/GET_COLLECTION_SUCCESS';

export const NOTES_GET_LIST = 'NOTES/GET_LIST';
export const NOTES_GET_LIST_SUCCESS = 'NOTES/GET_LIST_SUCCESS';

export const NOTES_GET_DETAIL = 'NOTES/GET_DETAIL';
export const NOTES_ADD = 'NOTES/ADD';
export const NOTES_UPDATE = 'NOTES/UPDATE';
export const NOTES_DELETE = 'NOTES/DELETE';
export const NOTES_ADD_COLLECTION = 'NOTES/ADD_COLLECTION';
export const NOTES_DELETE_COLLECTION = 'NOTES/DELETE_COLLECTION';
export const NOTES_UPDATE_COLLECTION = 'NOTES/UPDATE_COLLECTION';

export const NOTES_PERSONAL_GET_LIST = 'NOTES/PERSONAL_GET_LIST';
