import * as React from "react";
// import { useTranslation } from "react-i18next";
import { mergeRefs } from "react-merge-refs";
import fullPackage from "../../../../shared/editor/packages/full";
import { RefHandle } from "../../../components/ContentEditable";
import Editor, { Props as EditorProps } from "../../../components/Editor";
import Flex from "../../../components/Flex";
import { useDocumentContext } from "../../../components/DocumentContext";
import EditableTitle from "./EditableTitle";
import { useParams } from "react-router";
import { globalIntl } from "src/utils/hepler";

type Props = Omit<EditorProps, "extensions"> & {
  onChangeTitle: (text: string) => void;
  // document: Document;
  onSave: (options: {
    done?: boolean;
    autosave?: boolean;
    publish?: boolean;
  }) => void;
  children: React.ReactNode;
  title?: string;
};

/**
 * The main document editor includes an editable title with metadata below it,
 * and support for hover previews of internal links.
 */
function DocumentEditor(props: Props, ref: React.RefObject<any>) {
  const titleRef = React.useRef<RefHandle>(null);
  // const { t } = useTranslation();
  // const match = useRouteMatch();
  const {
    // document,
    onChangeTitle,
    // shareId,
    readOnly,
    children,
    title,
    ...rest
  } = props;
  const childRef = React.useRef<HTMLDivElement>(null);
  const focusAtStart = React.useCallback(() => {
    if (ref.current) {
      ref.current.focusAtStart();
    }
  }, [ref]);

  // Save document when blurring title, but delay so that if clicking on a
  // button this is allowed to execute first.
  const handleBlur = React.useCallback(() => {
    setTimeout(() => props.onSave({ autosave: true }), 250);
  }, [props]);

  const handleGoToNextInput = React.useCallback(
    (insertParagraph: boolean) => {
      if (insertParagraph && ref.current) {
        const { view } = ref.current;
        const { dispatch, state } = view;
        dispatch(state.tr.insert(0, state.schema.nodes.paragraph.create()));
      }

      focusAtStart();
    },
    [focusAtStart, ref]
  );

  const { setEditor } = useDocumentContext();
  const handleRefChanged = React.useCallback(setEditor, [setEditor]);


  return (
    <Flex auto column>
      <EditableTitle
        ref={titleRef}
        readOnly={readOnly}
        onGoToNextInput={handleGoToNextInput}
        onChange={onChangeTitle}
        // onBlur={handleBlur}
        // starrable={!shareId}
        title={props.title}
        placeholder={globalIntl("editor.title")}
      />
      <Editor
        ref={mergeRefs([ref, handleRefChanged])}
        // autoFocus={!!document.title && !props.defaultValue}
        placeholder={globalIntl("editor.placeHolderDocument")}
        scrollTo={decodeURIComponent(window.location.hash)}
        readOnly={readOnly}
        // shareId={shareId}
        extensions={fullPackage}
        bottomPadding={`calc(50vh - ${childRef.current?.offsetHeight || 0}px)`}
        {...rest}
      />
    </Flex>
  );
}

export default React.forwardRef(DocumentEditor);
