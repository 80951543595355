import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useMemo, FC, useState } from 'react';
import 'react-contexify/ReactContexify.css';
import { Menu, Item, useContextMenu } from 'react-contexify';
import copy from 'copy-to-clipboard';
import { Button, Form, message, Modal, Space, Input, Select } from 'antd';
import ChatTyping from './ChatTyping';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';
import { nanoid } from 'nanoid';
import { reqCreatePersonalNotes } from '../../redux/services/notes';

const MENU_ID = 'ContextMenu';

interface TProps {
  showLeft?: boolean;
  onLoad?: boolean;
  onReSend: (item: any, index: number) => void;
  conversations: any;
  contentRef: any;
}

const ChatBox: FC<TProps> = ({ conversations, contentRef, onReSend, showLeft = false, onLoad = false }) => {
  console.log('conversations', conversations);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);

  const { show } = useContextMenu({
    id: MENU_ID
  });

  function handleContextMenu(event: any, item: any) {
    show({ event, props: { ...item } });
  }

  const handleItemClick = ({ id, props }: any) => {
    switch (id) {
      case 'copy':
        copy(props.content);
        message.success('Content copied to clipboard!');
        break;
      case 'save':
        form.setFieldsValue({ content: props.content });
        setVisible(true);
        break;
    }
  };

  const onCopyCode = (content: string) => {
    copy(content);
    message.success('Code copied to clipboard!');
  };

  const onFinishSaveNote = async (data: any) => {
    try {
      await reqCreatePersonalNotes(data);
      message.success('Lưu note thành công!');
      setVisible(false);
      form.resetFields();
    } catch (e: any) {
      message.error(e.message);
    }
  };

  const renderContent = (text: string) => {
    return <div className='max-width-full w-full'>
      <ReactMarkdown
        components={{
          code({ node, inline, className, children, ...props }: any) {
            const content = String(children).replace(/\n$/, '');
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <div className='overflow-x-auto w-full' style={{ borderRadius: 10 }}>
                <div className='header bg-slate-900 text-white p-2'>
                  <div className='flex justify-between'>
                    <span>{match[1]}</span>
                    <span onClick={() => onCopyCode(content)}>Coppy</span>
                  </div>
                </div>
                <SyntaxHighlighter
                  style={okaidia}
                  language={match[1]}
                  customStyle={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    marginTop: 0,
                    background: '#000'
                  }}
                  PreTag='div'
                  {...props}
                >
                  {content}
                </SyntaxHighlighter>
              </div>
            ) : (
              <code style={{ whiteSpace: 'pre-line' }} className={className} {...props}>
                {children}
              </code>
            );
          }
        }}
      >
        {text}
      </ReactMarkdown>
    </div>;
  };
  const renderMessage = useMemo(() => {
    return conversations && conversations.map((item: any, index: number) => {
      return item.role !== 'user' ?
        <div key={nanoid()} className='place-self-start max-w-full cursor-pointer text-left'>
          <div onContextMenu={(event) => handleContextMenu(event, item)}
               className={`${item.status === 'error' && 'text-red-900'}  bg-white px-3 py-1 rounded-2xl rounded-tl-none`}>
            {item.status === 'error' && <InfoCircleOutlined />}
            {renderContent(item.content)}
          </div>
        </div> :
        <div key={nanoid()} className='place-self-end max-w-full cursor-pointer text-right'>
          <div
            onContextMenu={(event) => handleContextMenu(event, item)}
            className={`${item.status === 'error' ? 'text-red-900' : 'text-green-900'} bg-green-50 px-3 py-1 rounded-2xl rounded-tr-none`}>
            {item.status === 'error' && <InfoCircleOutlined />}
            {renderContent(item.content)}
            {item.status === 'error' && <div>
              <small onClick={() => onReSend(item, index)} className='text-cyan-500 cursor-pointer'>resend</small>
            </div>}
          </div>
        </div>;
    });
  }, [conversations]);

  return <>
    <div className={`box-messages relative ${showLeft ? 'max-h-full overflow-y-auto no-scrollbar' : ''}`}>
      <div ref={contentRef}
           className={`${showLeft ? 'max-h-full h-full' : 'max-h-96'} no-scrollbar overflow-y-auto`}>
        <div className='max-w-4xl mx-auto space-y-6 grid grid-cols-1'>
          {renderMessage}
          {onLoad && <div className='place-self-start cursor-pointer text-left'>
            <div className={`bg-white p-5 rounded-2xl rounded-tl-none`}>
              <ChatTyping />
            </div>
          </div>}

        </div>
      </div>
    </div>

    <Menu id={MENU_ID}>
      <Item id='copy' onClick={handleItemClick}>Copy</Item>
      <Item id='save' onClick={handleItemClick}>Save to My note</Item>
    </Menu>

    <Modal
      onCancel={() => setVisible(false)}
      open={visible}
      footer={<Space>
        <Button>Hủy</Button>
        <Button htmlType='submit' form='save-note' type='primary'>Lưu</Button>
      </Space>}
      title='My Note'>
      <Form onFinish={onFinishSaveNote} id='save-note' form={form} labelCol={{ sm: 8 }} labelAlign='left'>
        <Form.Item name='title' label='Tiêu đề'>
          <Input />
        </Form.Item>
        <Form.Item name='content' label='Nội dung'>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  </>;
};
export default ChatBox;
