import { ActionType, COMMON, MANAGE_ADMIN, MANAGE_MENTOR, MANAGE_STUDENT } from '../actions/actionTypes';
import { all, call, put, takeEvery, fork, takeLatest } from 'redux-saga/effects';
import {
  reqCreateAdminAccount,
  reqCreateMentorAccount,
  reqCreateStudentAccount,
  reqDeleteMentorAccount,
  reqDeleteStudentAccount,
  reqDetailAdminAccount,
  reqDetailMentorAccount,
  reqDetailStudentAccount,
  reqEditAdminAccount,
  reqEditMentorAccount,
  reqEditStudentAccount,
  reqGetListAdminAccount,
  reqGetListMentorAccount,
  reqGetListStudentAccount
} from '../services/admin';
import { CREATE_ADMIN, CREATE_MENTOR, CREATE_STUDENT, DELETE_MENTOR, DELETE_STUDENT, DETAIL_ADMIN, DETAIL_MENTOR, DETAIL_STUDENT, EDIT_ADMIN, EDIT_MENTOR, EDIT_STUDENT, GET_LIST_ADMIN, GET_LIST_MENTOR, GET_LIST_STUDENT } from '../actions/types';
import { IResponseListAdmin, IResponseDetailStudent, ResponseCreateMentortAccount, IResponseDetailMentor, ResponseEditStudentAccount, IResponseListStudent, IResponseListMentor, IResponseDetailAdmin } from '../types/Admin';
import { history } from '../store';
import { message } from 'antd';
import { globalIntl } from '../../utils/hepler';
import { ApiResponse } from 'src/utils/AxiosHelper';


function* getAdminAccountList() {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<IResponseListAdmin> = yield call(reqGetListAdminAccount);
    yield put(MANAGE_ADMIN.GET_ACCOUNT_SUCCESS(response.data));
    yield put(MANAGE_ADMIN.SET_STATE({ loading: false, error: false }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* createAdminAccount({ payload }: ActionType) {
  try {
    yield put(MANAGE_ADMIN.SET_STATE({ loading: true }));
    yield call(reqCreateAdminAccount, payload);
    yield put(MANAGE_ADMIN.SET_STATE({ loading: false, error: false }));
    const msg = globalIntl('admin.create_success');
    message.success(msg);
    history.goBack();
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(MANAGE_ADMIN.SET_STATE({ loading: false, error: true }));
  }
}

function* detailAdminAccount({ payload }: ActionType) {
  try {
    yield put(MANAGE_ADMIN.SET_STATE({ loading: true }));
    const response: ApiResponse<IResponseDetailAdmin> = yield call(reqDetailAdminAccount, payload);
    yield put(MANAGE_ADMIN.DETAIL_ACCOUNT_SUCCESS(response.data));
    yield put(MANAGE_ADMIN.SET_STATE({ loading: false, error: false }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(MANAGE_ADMIN.SET_STATE({ loading: false, error: true }));
  }
}

function* editAdminAccount({ payload }: ActionType) {
  try {
    yield put(MANAGE_ADMIN.SET_STATE({ loading: true }));
    yield call(reqEditAdminAccount, payload);
    yield put(MANAGE_ADMIN.SET_STATE({ loading: false, error: false }));
    const msg = globalIntl('admin.edit_success');
    history.goBack();
    message.success(msg);

  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(MANAGE_ADMIN.SET_STATE({ loading: false, error: true }));
  }
}

function* onEditAdminAccount() {
  yield takeEvery(EDIT_ADMIN, editAdminAccount);
}

function* getMentorAccountList({ payload }: ActionType) {
    console.log('getMentorAccountList', payload);
    try {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: true }));
      const response:ApiResponse<IResponseListMentor> = yield call(reqGetListMentorAccount);
      yield put(MANAGE_MENTOR.GET_ACCOUNT_SUCCESS(response.data));
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: false }));
    } catch (e) {
      message.error(e.message);
    } finally {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: true }));
    }
  }
  
  function* createMentorAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: true }));
      yield call(reqCreateMentorAccount, payload);
      yield put(MANAGE_MENTOR.CREATE_ACCOUNT_SUCCESS());
      const msg = globalIntl('admin.create_success');
      message.success(msg);
      history.goBack();
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: false }));
    } catch (e) {
      message.error(e.message);
    } finally {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: true }));
    }
  }
  
  function* deleteMentorAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: true }));
      yield call(reqDeleteMentorAccount, payload);
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: false }));
      const msg = globalIntl('admin.delete_success');
      history.goBack();
      message.success(msg);
    } catch (e) {
      message.error(e.message);
    } finally {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: true }));
    }
  }
  
  function* detailMentorAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: true }));
      const response: ApiResponse<IResponseDetailMentor> = yield call(reqDetailMentorAccount, payload);
      yield put(MANAGE_MENTOR.DETAIL_ACCOUNT_SUCCESS(response.data));
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: false }));
  
    } catch (e) {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: true }));
    }
  }

  function* onEditMentorAccount() {
    yield takeEvery(EDIT_MENTOR, editMentorAccount);
  }
  function* editMentorAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: true }));
      yield call(reqEditMentorAccount, payload);
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: false }));
      const msg = globalIntl('admin.edit_success');
      history.goBack();
      message.success(msg);
  
    } catch (e) {
      message.error(e.message);
    } finally {
      yield put(MANAGE_MENTOR.SET_STATE({ loading: false, error: true }));
    }
  }

  function* getStudentAccountList() {
    try {
      yield put(COMMON.LOADING(true));
      const response: ApiResponse<IResponseListStudent> = yield call(reqGetListStudentAccount);
      yield put(MANAGE_STUDENT.GET_ACCOUNT_SUCCESS(response.data));
    } catch (e) {
      message.error(e.message);
    } finally {
      yield put(COMMON.LOADING(false));
    }
  }
  
  function* createStudentAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: true }));
      yield call(reqCreateStudentAccount, payload);
      const msg = globalIntl('admin.create_success');
      message.success(msg);
      history.goBack();
    } catch (e: any) {
      message.error(e.message);
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: true }));
    } finally {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false }));
    }
  }
  
  
  function* deleteStudentAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: true }));
      yield call(reqDeleteStudentAccount, payload);
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: false }));
      const msg = globalIntl('admin.delete_success');
      message.success(msg);
      history.goBack();
    } catch (e) {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: true }));
    } finally {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: false }));
    }
  }
  
  function* detailStudentAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: true }));
      const response: ApiResponse<IResponseDetailStudent> = yield call(reqDetailStudentAccount, payload);
      yield put(MANAGE_STUDENT.DETAIL_ACCOUNT_SUCCESS(response.data));
  
    } catch (e) {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: true }));
    } finally {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: false }));
    }
  }
  
  function* editStudentAccount({ payload }: ActionType) {
    try {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: true }));
      yield call(reqEditStudentAccount, payload);
      yield put(MANAGE_STUDENT.EDIT_ACCOUNT_SUCCESS());
      const msg = globalIntl('admin.edit_success');
      history.goBack();
      message.success(msg);
    } catch (e) {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: true }));
    } finally {
      yield put(MANAGE_STUDENT.SET_STATE({ loading: false, error: false }));
    }
  }
  
  function* onEditStudentAccount() {
    yield takeEvery(EDIT_STUDENT, editStudentAccount);
  }

export default function* rootSaga() {
    yield all([
        takeLatest(GET_LIST_ADMIN, getAdminAccountList),
        takeLatest(CREATE_ADMIN, createAdminAccount),
        takeLatest(DETAIL_ADMIN, detailAdminAccount),
        fork(onEditAdminAccount),
        takeEvery(GET_LIST_MENTOR, getMentorAccountList),
        takeEvery(CREATE_MENTOR, createMentorAccount),
        takeEvery(DELETE_MENTOR, deleteMentorAccount),
        takeEvery(DETAIL_MENTOR, detailMentorAccount),
        fork(onEditMentorAccount),
        takeEvery(GET_LIST_STUDENT, getStudentAccountList),
        takeLatest(CREATE_STUDENT, createStudentAccount),
        takeLatest(DELETE_STUDENT, deleteStudentAccount),
        takeLatest(DETAIL_STUDENT, detailStudentAccount),
        fork(onEditStudentAccount)
    ]);
}
