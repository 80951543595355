import { lazy } from 'react';
import PersonalNotes from 'src/pages/student/personal_notes';

const CollectionQuizAdmin = lazy(() => import('../../pages/admin/manage_quiz'));
const QuizAdmin = lazy(() => import('../../pages/admin/manage_quiz/QuizAdmin'));
const DocumentScene = lazy(() => import('../../editor/app/scenes/Document/components/Document'));
const MyNotes = lazy(() => import('../../pages/student/mynotes'));
const CollectionQuiz = lazy(() => import('../../pages/mentor/quiz/index'));
const Quiz = lazy(() => import('../../pages/mentor/quiz/Quiz'));
const ListStudent = lazy(() => import('../../pages/admin/manage_student/list_account'));
const EditStudent = lazy(() => import('../../pages/admin/manage_student/edit_account'));
const CreateStudent = lazy(() => import('../../pages/admin/manage_student/create_account'));
const DetailStudent = lazy(() => import('../../pages/admin/manage_student/detail_account'));
const ListAdmin = lazy(() => import('../../pages/admin/manage_admin/list_account'));
const DetailAdmin = lazy(() => import('../../pages/admin/manage_admin/detail_account'));
const CreateAdmin = lazy(() => import('../../pages/admin/manage_admin/create_account'));
const ListMentor = lazy(() => import('../../pages/admin/manage_mentor/list_account'));
const EditMentor = lazy(() => import('../../pages/admin/manage_mentor/edit_account'));
const CreateMentor = lazy(() => import('../../pages/admin/manage_mentor/create_account'));
const DetailMentor = lazy(() => import('../../pages/admin/manage_mentor/detail_account'));
const CourseDetail = lazy(() => import('../../pages/mentor/course/Detail'));
const MentorOverview = lazy(() => import('../../pages/mentor/overview/MentorOverview'));
const CourseList = lazy(() => import('../../pages/mentor/course'));
const SubjectEdit = lazy(() => import('../../pages/mentor/course/SubjectEdit'));
const LessonGroup = lazy(() => import('../../pages/mentor/course/LessonGroup'));
const StudentOverview = lazy(() => import('../../pages/student/overview'));
const CareerPath = lazy(() => import('../../pages/student/careerpath/index'));
const StdCourse = lazy(() => import('../../pages/student/course'));
const AdminOverView = lazy(() => import('../../pages/admin/manage_overview'));
const StdCourseDetail = lazy(() => import('../../pages/student/course/StdCourseDetail'));
const StdCourseArticle = lazy(() => import('../../pages/student/course/StdCourseArticle'));
const AdminCourseList = lazy(() => import('../../pages/admin/manage_course'));
const AdminCourseDetail = lazy(() => import('../../pages/admin/manage_course/Detail'));
const AdminSubjectEdit = lazy(() => import('../../pages/admin/manage_course/SubjectEdit'));
const AdminLessonGroup = lazy(() => import('../../pages/admin/manage_course/LessonGroup'));
const ChatWithMentee = lazy(() => import('../../pages/mentor/chat'));
const ChatRoom = lazy(() => import('../../pages/mentor/chat/ChatRoom'));
const ScheduleRegister = lazy(() => import('src/pages/mentor/schedule'));
const InterView = lazy(() => import('../../pages/mentor/interview/index'));

const Editor = 'src/editor';

export interface IRoute {
  exact?: boolean,
  key?: string | number,
  path?: string,
  component?: any,
  items?: IRoute[]
}

export interface IRouteRole {
  [role: string]: IRoute[] | null,
}

export const routers: IRouteRole = {
  mentor: [
    {
      component: MentorOverview,
      key: 'mentor.overview',
      path: '/',
      exact: true
    },
    {
      component: CourseList,
      key: 'course-management',
      path: '/course-management'
    },
    {
      component: ScheduleRegister,
      key: 'schedule-register',
      path: '/schedule-register'
    },
    {
      component: ChatWithMentee,
      key: 'mentee.chat.pending',
      path: '/mentee-chat',
      exact: true
    },
    {
      component: ChatWithMentee,
      key: 'mentee.chat.processing',
      path: '/mentee-chat/processing'
    },
    {
      component: ChatRoom,
      key: 'mentee.chat.room',
      path: '/mentee-chat/:roomId',
      exact: true
    },
    {
      component: CollectionQuiz,
      key: 'quiz',
      path: '/quiz',
    },
  ],
  admin: [
    {
      component: AdminOverView,
      key: 'management-overview-student',
      path: '/',
      exact: true
    },
    {
      component: ListStudent,
      key: 'student.list-account',
      path: '/list-student',
      exact: true
    },
    {
      component: DetailStudent,
      key: 'student.detail-account',
      path: '/detail-student/:id'
    },
    {
      component: CreateStudent,
      key: 'student.create-account',
      path: '/create-student'
    },
    {
      component: EditStudent,
      key: 'student.edit-account',
      path: '/edit-student/:id'
    }
    ,
    {
      component: ListAdmin,
      key: 'admin.list-admin',
      path: '/list-admin',
      exact: true
    }
    ,
    {
      component: DetailAdmin,
      key: 'admin.edit-admin',
      path: '/detail-admin/:id',
      exact: true
    }
    ,
    {
      component: CreateAdmin,
      key: 'admin.create-admin',
      path: '/create-admin',
      exact: true
    },
    {
      component: ListMentor,
      key: 'mentor.list-account',
      path: '/list-mentor',
      exact: true
    },
    {
      component: DetailMentor,
      key: 'mentor.detail-account',
      path: '/detail-mentor/:id'
    },
    {
      component: CreateMentor,
      key: 'mentor.create-account',
      path: '/create-mentor'
    },
    {
      component: EditMentor,
      key: 'mentor.edit-account',
      path: '/edit-mentor/:id'
    },
    {
      component: AdminCourseList,
      key: 'admin.course',
      path: '/course',
      exact: true
    },
    {
      component: AdminCourseDetail,
      key: 'admin.course.detail',
      path: '/course/:id',
      exact: true
    },
    {
      component: AdminSubjectEdit,
      key: 'admin.subject',
      path: '/course/subject/:subjectId'
    },
    {
      component: AdminLessonGroup,
      key: 'admin.course.lesson',
      path: '/course/lesson-group/:groupId'
    },
    {
      component: CollectionQuizAdmin,
      key: 'management-quiz',
      path: '/management-quiz'
    },
  ],
  student: [
    {
      component: StdCourse,
      key: 'student-course',
      path: '/course'
    },
    {
      component: StdCourseDetail,
      key: 'student-course-view',
      path: '/course/:courseId'
    },
    {
      component: StdCourseArticle,
      key: 'student-course-article',
      path: '/article/:courseId/:subjectId/:groupId'
    }
  ]
};
export const publicRoutes: IRouteRole = {
  admin: [
    {
      component: DocumentScene,
      key: 'admin.editor',
      path: '/editor',
      exact: true
    },
    {
      component: AdminCourseList,
      key: 'admin.course',
      path: '/course',
      exact: true
    },
    {
      component: AdminCourseDetail,
      key: 'admin.course.detail',
      path: '/course/:id',
      exact: true
    },
    {
      component: AdminSubjectEdit,
      key: 'admin.course.subject',
      path: '/course/subject/:subjectId'
    },
    {
      component: AdminLessonGroup,
      key: 'admin.course.lesson',
      path: '/course/lesson-group/:groupId'
    },
    {
      component: QuizAdmin,
      key: 'admin.quiz.detail',
      path: '/quiz-admin/:id',
      exact: true
    },
  ],
  student: [
    {
      component: DocumentScene,
      key: 'student.editor',
      path: '/editor',
      exact: true
    },
    {
      component: StudentOverview,
      key: 'student-overview',
      path: '/',
      exact: true
    },
    {
      component: CareerPath,
      key: 'student-career-path',
      path: '/career-path'
    },
    {
      component: StdCourse,
      key: 'student-course',
      path: '/course',
      exact: true
    },
    {
      component: MyNotes,
      key: 'student-notes',
      path: '/my-notes'
    },
    // {
    //   component: Practice,
    //   key: 'student-practice-online',
    //   path: '/practice-online'
    // },
    {
      component: StudentOverview,
      key: 'student-chat-mentor',
      path: '/chat-with-mentor'
    },
    {
      component: StudentOverview,
      key: 'student-interview',
      path: '/interview'
    },
    {
      component: StudentOverview,
      key: 'student-settings',
      path: '/settings'
    },
    {
      component: StdCourseArticle,
      key: 'student-course-article',
      path: '/article/:courseId/:subjectId/:groupId'
    },
    {
      component: StdCourseDetail,
      key: 'student-course-view',
      path: '/course/:courseId'
    },
    {
      component: PersonalNotes,
      key: 'student-personal-notes',
      path: '/personal-notes/:id'
    }
  ],
  mentor: [
    //mentor
    {
      component: CourseList,
      key: 'course-management',
      path: '/course-management'
    },
    {
      component: CourseDetail,
      key: 'course.detail',
      path: '/course/:id',
      exact: true
    },
    {
      component: Quiz,
      key: 'quiz.detail',
      path: '/quiz-detail/:id',
      exact: true
    },
    {
      component: SubjectEdit,
      key: 'course.subject.edit',
      path: '/course/subject/:subjectId'
    },
    {
      component: LessonGroup,
      key: 'course.lesson.edit',
      path: '/course/lesson-group/:groupId'
    },
    {
      component: ChatWithMentee,
      key: 'mentee.chat.pending',
      path: '/mentee-chat',
      exact: true
    },
    {
      component: ChatWithMentee,
      key: 'mentee.chat.processing',
      path: '/mentee-chat/processing'
    },
    {
      component: ChatRoom,
      key: 'mentee.chat.room',
      path: '/mentee-chat/:roomId',
      exact: true
    },
    {
      component: InterView,
      key: 'interview',
      path: '/interview'
    }
  ]
};
