import { ICourseResponse, ILessonItem, ISubjectItem, ISubjectResponse } from '../types/Mentor';
import { axiosAuth, axiosCatch } from '../../utils/AxiosHelper';
import { AxiosResponse } from 'axios';

const getType = () => {
  const typeLogin = localStorage.getItem('typeLogin');
  return typeLogin === 'mentor' ? 'mentor' : 'admin';
};
export const reqGetCourse = async (params: any = {}): Promise<AxiosResponse<ICourseResponse>> => {
  return await axiosAuth().get(`/${getType()}/course/list`, { params }).catch(axiosCatch);
};
export const reqGetCourseDetail = async (courseId: string | number): Promise<AxiosResponse<ICourseResponse>> => {
  return axiosAuth().get(`/${getType()}/course/info`, { params: { courseId } }).catch(axiosCatch);
};
export const reqGetLessonGroup = async (params: any = {}) => {
  return axiosAuth().get(`/${getType()}/lesson-group/info`, { params }).catch(axiosCatch);
};
export const reqAddSubject = async (subject: ISubjectItem) => {
  return axiosAuth().post(`/${getType()}/subject/add`, subject).catch(axiosCatch);
};
export const reqUpdateSubject = async (subject: ISubjectItem) => {
  return axiosAuth().post(`/${getType()}/subject/update`, subject).catch(axiosCatch);
};
export const reqDelSubject = async (payload: string) => {
  return axiosAuth().post(`/${getType()}/subject/delete`, payload).catch(axiosCatch);
};
export const reqGetSubject = async (params: any = {}): Promise<AxiosResponse<ISubjectResponse>> => {
  return axiosAuth().get(`/${getType()}/subject/info`, { params }).catch(axiosCatch);
};
export const reqAddLesson = async (lesson: any) => {
  return axiosAuth().post(`/${getType()}/lesson/add`, lesson).catch(axiosCatch);
};
export const reqGetLesson = async (params: any = {}) => {
  return axiosAuth().post(`/${getType()}/lesson/get`, { params }).catch(axiosCatch);
};
export const reqAddCourse = async (course: any) => {
  return axiosAuth().post(`/${getType()}/course/add`, course).catch(axiosCatch);
};

export const reqUpdateCourse = async (course: any) => {
  return axiosAuth().post(`/${getType()}/course/update`, course).catch(axiosCatch);
};
export const reqDelCourse = async (courseId: string) => {
  return axiosAuth().post(`/${getType()}/course/delete`, courseId).catch(axiosCatch);
};
export const reqUpdateGroup = async (group: any) => {
  return axiosAuth().post(`/${getType()}/lesson-group/update`, group).catch(axiosCatch);
};
export const reqAddGroup = async (group: any) => {
  return axiosAuth().post(`/${getType()}/lesson-group/add`, group).catch(axiosCatch);
};
export const reqUpdateLesson = async (lesson: ILessonItem) => {
  return axiosAuth().post(`/${getType()}/lesson/update`, lesson).catch(axiosCatch);
};
export const reqDelGroup = async (params: any) => {
  return axiosAuth().post(`/${getType()}/lesson-group/delete`, params).catch(axiosCatch);
};
export const reqDeleteLesson = async (data: { lessonId: string }) => {
  return axiosAuth().post(`/${getType()}/lesson/delete`, data).catch(axiosCatch);
};
