import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  QUIZ_COLLECTION_CREATE,
  QUIZ_COLLECTION_DELETE,
  QUIZ_COLLECTION_INFO,
  QUIZ_COLLECTION_LIST,
  QUIZ_COLLECTION_UPDATE,
  QUIZ_CREATE,
  QUIZ_DELETE,
  QUIZ_GET_LIST,
  QUIZ_GET_QES,
  QUIZ_INFO,
  QUIZ_STATE,
  QUIZ_UPDATE
} from '../actions/types';
import { message } from 'antd';
import 'firebase/compat/database';
import {
  reqCreateCollectionQuiz,
  reqCreateQuiz,
  reqDeleteCollectionQuiz,
  reqDeleteQuiz,
  reqGetCollectionQuiz,
  reqInfoCollectionQuiz,
  reqInfoQuiz,
  reqUpdateCollectionQuiz,
  reqUpdateQuiz
} from '../services/admin';
import { ApiResponse } from 'src/utils/AxiosHelper';
import { ResponseCollectionQuiz, ResponseCollectionQuizDetail, ResponseInfoQuiz } from '../types/Admin';
import { ActionType, QUIZ } from '../actions/actionTypes';
import { reqGetCollectionList, reqQuizInfo } from '../services/quizCollection';
import { decryptQuiz } from '../../utils/hepler';

const SECRET_QUIZ_KEY = process.env.REACT_APP_SECRET_QUIZ_KEY;

const mapQuizAdmin = (data: any) => {
  try {
    const content = Object.entries(data);
    const admin = content.map(([adminId, item]: any) => {
      const group = Object.entries(item);
      return group.map(([groupId, group]: any) => {
        if (!group.quizCollectionData) {
          return {
            groupId: groupId,
            groupName: group.quizCollectionName,
            list: []
          };
        }
        const value = Object.entries(group.quizCollectionData).map(([quizId, value]: any) => {
          return { quizId, ...value };
        });
        return {
          groupId: `${adminId}/${groupId}`,
          groupName: group.quizCollectionName,
          list: value
        };
      });
    });
    return admin.flat().filter(i => !!i);
  } catch (e) {
    return [];
  }
};

const mapQuizMentor = (data: any) => {
  const content = Object.entries(data);
  return content.map(([groupId, item]: any) => {
    const value = Object.entries(item.quizCollectionData).map(([quizId, val]: any) => {
      return { quizId, ...val };
    });
    return {
      groupId: `${item.mentorId}/${groupId}`,
      groupName: item.quizCollectionName,
      list: value
    };
  });
};

function* onQuizList({ payload }: any) {
  try {
    const response: ApiResponse<any> = yield call(reqGetCollectionList);
    yield put({
      type: QUIZ_STATE,
      payload: {
        collections: response.data.data.list
      }
    });
  } catch (e) {
    message.error(e.message);
  }
}

function* onQuestion({ payload }: any) {
  try {
    const response: ApiResponse<any> = yield call(reqQuizInfo, payload);
    const questions = response.data.data;
    const content = questions.quizContent && questions.quizContent.length > 0 && questions.quizContent[0].quizContent;
    yield put({
      type: QUIZ_STATE,
      payload: {
        questions: {
          quizContent: decryptQuiz(content, SECRET_QUIZ_KEY)
        }
      }
    });
  } catch (e) {
    message.error(e.message);
  }
}

function* onGetCollectionQuiz() {
  try {

    const response: ApiResponse<ResponseCollectionQuiz> = yield call(reqGetCollectionQuiz);

    yield put({
      type: QUIZ_STATE,
      payload: {
        quizListCollection: response.data.data.list
      }
    });

  } catch (e) {
    message.error(e.message);
  }
}


function* onCreateCollectionQuiz({ payload }: ActionType) {
  try {

    const response: ApiResponse<any> = yield call(reqCreateCollectionQuiz, payload);
    message.success('Tạo bộ quiz thành công!');
    console.log('response', response.status);

    if (response.status == 200) {
      console.log('response', response);
      yield put(QUIZ.GET_QUIZ_COLLECTION(null));
    }

  } catch (e) {
    message.error('Tạo bộ quiz thất bại!');
  }
}

function* onUpdateCollectionQuiz({ payload }: ActionType) {
  try {

    const response: ApiResponse<any> = yield call(reqUpdateCollectionQuiz, payload);
    message.success('Sửa tên bộ quiz thành công!');
    console.log('response', response.status);

    if (response.status == 200) {
      console.log('response', response);
      yield put(QUIZ.GET_QUIZ_COLLECTION(null));
    }

  } catch (e) {
    message.error('Sửa tên bộ quiz thất bại!');
  }
}

function* onDeleteCollectionQuiz({ payload }: ActionType) {
  try {

    const response: ApiResponse<any> = yield call(reqDeleteCollectionQuiz, payload);
    message.success('Xoá bộ quiz thành công!');
    console.log('response', response.status);

    if (response.status == 200) {
      console.log('response', response);
      yield put(QUIZ.GET_QUIZ_COLLECTION(null));
    }

  } catch (e) {
    message.error('Xoá bộ quiz thất bại!');
  }
}

function* onInfoCollectionQuiz({ payload }: ActionType) {
  try {

    const response: ApiResponse<ResponseCollectionQuizDetail> = yield call(reqInfoCollectionQuiz, payload);
    console.log('response', response.status);

    yield put({
      type: QUIZ_STATE,
      payload: {
        quizCollectionDetail: response.data.data
      }
    });

  } catch (e) {
    message.error('Tải danh sách quiz thất bại!');
  }
}

function* onCreateQuiz({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqCreateQuiz, payload);
    if (response.status == 200) {
      message.success('Tạo quiz thành công!');
      yield put(QUIZ.INFO_QUIZ_COLLECTION({ collectionQuizId: payload.collectionQuizId }));
    }
  } catch (e) {
    message.error('Tạo quiz thất bại!');
  }
}

function* onDeleteQuiz({ payload }: ActionType) {
  try {

    const response: ApiResponse<any> = yield call(reqDeleteQuiz, { quizId: payload.quizId });
    console.log('response', response.status);
    message.success('Xoá quiz thành công!');
    console.log('response', response.status);

    if (response.status == 200) {
      console.log('response', response);
      yield put(QUIZ.INFO_QUIZ_COLLECTION({ collectionQuizId: payload.collectionQuizId }));

    }


  } catch (e) {
    message.error('Xoá quiz thất bại!');
  }
}

function* onUpdateQuiz({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqUpdateQuiz, {
      name: payload.name,
      quizContent: payload.quizContent,
      quizId: payload.quizId
    });

    if (response.status == 200) {
      message.success('Cập nhật quiz thành công!');
      yield put(QUIZ.INFO_QUIZ_COLLECTION({ collectionQuizId: payload.collectionQuizId }));
      yield put({
        type: QUIZ_STATE,
        payload: {
          quizInfo: undefined
        }
      });
    }
  } catch (e) {
    message.error('Cập nhật quiz thất bại!');
  }
}

function* onInfoQuiz({ payload }: ActionType) {
  try {

    const response: ApiResponse<ResponseInfoQuiz> = yield call(reqInfoQuiz, payload);
    yield put({
      type: QUIZ_STATE,
      payload: {
        quizInfo: response.data.data
      }
    });


  } catch (e) {
    message.error('Lấy thông tin quiz thất bại!');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(QUIZ_GET_LIST, onQuizList),
    takeLatest(QUIZ_GET_QES, onQuestion),
    takeLatest(QUIZ_COLLECTION_LIST, onGetCollectionQuiz),
    takeLatest(QUIZ_COLLECTION_CREATE, onCreateCollectionQuiz),
    takeLatest(QUIZ_COLLECTION_UPDATE, onUpdateCollectionQuiz),
    takeLatest(QUIZ_COLLECTION_DELETE, onDeleteCollectionQuiz),
    takeLatest(QUIZ_COLLECTION_INFO, onInfoCollectionQuiz),
    takeLatest(QUIZ_CREATE, onCreateQuiz),
    takeLatest(QUIZ_DELETE, onDeleteQuiz),
    takeLatest(QUIZ_UPDATE, onUpdateQuiz),
    takeLatest(QUIZ_INFO, onInfoQuiz),
  ]);
}
