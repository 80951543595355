import { Button, Col, Form, Input, Row } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { FC } from 'react';

interface TProps {
  onFinish: (data: any) => void;
  form: any;
  loading: boolean;
  inputRef: any;
}

const ChatForm: FC<TProps> = ({ inputRef, onFinish, form, loading = false }) => {
  return <>
    <div className='input'>
      <Form onFinish={onFinish} form={form} style={{ width: '100%' }} size='large'>
        <Row gutter={5}>
          <Col md={20}>
            <Form.Item name='message' noStyle>
              <Input.TextArea
                onPressEnter={(e: any) => {
                  if (!e.shiftKey) {
                    form.submit();
                  }
                }}
                autoSize={{ maxRows: 3 }}
                ref={inputRef} readOnly={loading}
                placeholder='Type your question...' />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Button disabled={loading} style={{ width: '100%' }} htmlType='submit'>
              <SendOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  </>;
};
export default ChatForm;
