import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../../containers/PageNotFound';
import { IRoute, IRouteRole, publicRoutes, routers } from './routers';
import { FC, useEffect, useState } from 'react';

interface AppProps {
  path?: string;
}

const AppRoute: FC<AppProps> = ({ path = '' }) => {
  const [routeList, setRouteList] = useState<IRoute[]>([]);

  useEffect(() => {
    try {
      const roles = JSON.parse((localStorage.getItem('roles')!));
      const uniqueRole = [...new Set(roles.flat())];
      const typeLogin = localStorage.getItem('typeLogin') as keyof IRouteRole;
      const routes = routers[typeLogin];
      const publicRoute = publicRoutes[typeLogin];
      const list = routes && routes.reduce((initial: IRoute[], item: IRoute) => (uniqueRole.includes(item.path) ? [...initial, item] : initial), routeList);
      console.log('phong list',routes, list);
      
      setRouteList([...list ?? [], ...publicRoute ?? []]);
    } catch (e) {
      setRouteList([]);
    }
  }, []);

  return <Switch>
    {routeList && routeList.map((value: any, index: number) => {
      return !value.items ?
        <Route
          exact={value.exact}
          key={`ridx_${index}`}
          path={`${path}${value.path}`}
          component={value.component}
        />
        :
        value.items.map((item: any, key: number) => <Route
          exact={item.exact}
          key={`ridxxx_${index}_${key}`}
          path={`${path}${item.path}`}
          component={item.component}
        />);
    })}
    <Route component={PageNotFound} />
  </Switch>;
};
export default AppRoute;
