import axios from 'axios';
import { history } from 'src/redux/store';
import { Modal } from 'antd';

export interface ApiResponse<T> {
  data: T,
  message?: string | number
  code?: string | number,
  status?: string | number
}

export interface RefreshTokenResponse {
  data: {
    accessToken: string,
    refreshToken: string
  };
}

const { REACT_APP_API_URL, REACT_APP_API_ARTICLE, REACT_APP_API_ARTICLE_TOKEN } = process.env;


const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const docApi = axios.create({
  baseURL: REACT_APP_API_ARTICLE,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${REACT_APP_API_ARTICLE_TOKEN}`
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const authToken = localStorage.getItem('auth');
      try {
        const response = await axios.post(`${REACT_APP_API_URL}/auth/refresh-token`, { refreshToken }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });
        localStorage.setItem('auth', response.data.data.accessToken);
        localStorage.setItem('refreshToken', response.data.data.refreshToken);
        instance.defaults.headers.common.Authorization = `Bearer ${response.data.data.accessToken}`;
        return instance(originalRequest);
      } catch (error) {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('refreshToken');
        const typeLogin = localStorage.getItem('typeLogin');
        const path = typeLogin == 'admin' ? '/login-admin' : typeLogin === 'mentor' ? '/login-mentor' : '/login';
        if (!history.location.pathname.match(/login/i)) {
          window.location.replace(path);
        }
      }
    }
    return Promise.reject(error);
  }
);

export const axiosAuth = () => instance;

export const axiosCatch = (error: any) => {
  if (error.response) {
    throw error.response.data;
  } else if (error.request) {
    throw {
      message: error.request.data.responseText
    };
  } else {
    throw error;
  }
};

export const UPLOAD_HEADER = {
  'Content-Type': 'multipart/form-data'
};

// get data


//service
export const upload = async (formData: FormData) => axiosAuth().post('api/uploads', formData, {
  headers: { ...UPLOAD_HEADER }
});

// call api
