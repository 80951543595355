import { ActionType, COMMON, COURSE } from '../actions/actionTypes';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  COURSE_ADD,
  COURSE_ADD_GROUP,
  COURSE_ADD_LESSON,
  COURSE_ADD_SUBJECT, COURSE_ADD_SUBJECT_CACHE, COURSE_ADD_SUBJECT_CACHE_SUCCESS, COURSE_DEL_GROUP,
  COURSE_DEL_LESSON,
  COURSE_DELETE,
  COURSE_DELETE_SUBJECT,
  COURSE_GET_DETAIL,
  COURSE_GET_LIST,
  COURSE_GET_SUBJECT,
  COURSE_GROUP_DETAIL,
  COURSE_UPDATE,
  COURSE_UPDATE_GROUP,
  COURSE_UPDATE_LESSON,
  COURSE_UPDATE_SUBJECT
} from '../actions/types';
import {
  ECourseType,
  ESubjectType,
  ICourseDetailResponse,
  ICourseResponse,
  ILessonGroupResponse,
  ISubjectResponse
} from '../types/Mentor';
import {
  reqAddCourse, reqAddGroup, reqAddLesson,
  reqAddSubject, reqDelCourse, reqDeleteLesson, reqDelGroup, reqDelSubject,
  reqGetCourse,
  reqGetCourseDetail,
  reqGetLessonGroup, reqGetSubject, reqUpdateCourse, reqUpdateGroup,
  reqUpdateLesson, reqUpdateSubject
} from '../services/course';
import { message } from 'antd';
import { ApiResponse } from '../../utils/AxiosHelper';
import { history } from '../store';

function* getCourseList({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<ICourseResponse> = yield call(reqGetCourse, payload);
    const specializedList = response.data.listCourse.filter((course) => course.typeCourse === ECourseType.necessary);
    const basicList = response.data.listCourse.filter((course) => course.typeCourse === ECourseType.basic);
    const allList = response.data.listCourse;
    yield put(COURSE.COURSE_STATE({ basicList, specializedList, allList }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* getCourseDetail({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<ICourseDetailResponse> = yield call(reqGetCourseDetail, payload);
    yield put(COURSE.COURSE_STATE({
      courseDetail: response.data
    }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* getLessonGroup({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<ILessonGroupResponse> = yield call(reqGetLessonGroup, { lessonGroupId: payload });
    yield put(COURSE.COURSE_STATE({ group: response.data }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onAddSubject({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqAddSubject, payload);
    const subjectType = payload.typeSubject === ESubjectType.learning ? 'môn học' : 'môn thi';
    yield put(COURSE.GET_DETAIL(payload.courseId));
    yield message.success(`Cập nhật thành công!`);
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));
  }
}

function* onAddSubjectCache({ payload }: ActionType) {
  try {
    const subjectType = payload.typeSubject === ESubjectType.learning ? 'môn học' : 'môn thi';
    yield put({
      type: COURSE_ADD_SUBJECT_CACHE_SUCCESS,
      payload
    });
    yield message.success(`Thêm ${subjectType} thành công!`);
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));
  }
}

function* onUpdateSubject({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqUpdateSubject, payload);
    const subjectType = payload.typeSubject === ESubjectType.learning ? 'môn học' : 'môn thi';
    // yield put(COURSE.GET_DETAIL(payload.courseId));
    yield message.success(`Cập nhật ${subjectType} thành công!`);
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));
  }
}

function* onDelSubject({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqDelSubject, payload);
    message.success(`Xóa môn học thành công!`);
    history.goBack();
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));
  }
}

function* onGetSubject({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    const response: ApiResponse<ISubjectResponse> = yield call(reqGetSubject, { subjectId: payload });
    yield put(COURSE.COURSE_STATE({ subject: response.data, error: false }));
  } catch (e: any) {
    yield put(COURSE.COURSE_STATE({ error: true }));
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false }));
  }
}

function* onAddCourse({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true }));
    yield call(reqAddCourse, payload);
    yield put(COURSE.GET_LIST());
    message.success(`Thêm chuyên ngành thành công!`);
  } catch (e: any) {
    yield put(COMMON.CLOSE_MODAL(false));
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false }));
  }
}

function* onUpdateCourse({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqUpdateCourse, { description: payload.description, title: payload.title, courseId: payload.id });
    yield put(COURSE.GET_LIST());
    message.success(`Chỉnh sửa chuyên ngành thành công!`);
  } catch (e: any) {
    yield put(COURSE.COURSE_STATE({ error: true }));
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: false }));
  }
}

function* onDeleteCourse({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true }));
    yield call(reqDelCourse, payload);
    message.success(`Xoá chuyên ngành thành công!`);
    yield put(COURSE.GET_LIST());
  } catch (e) {
    yield put(COURSE.COURSE_STATE({ error: true }));
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false }));
  }
}

function* onUpdateGroup({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqUpdateGroup, payload);
    yield put(COURSE.GET_LESSON_GROUP(payload.lessonGroupId));
    message.success(`Cập nhật nhóm bài học thành công!`);
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));
  }
}

function* onAddGroup({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqAddGroup, payload);
    yield put(COURSE.GET_SUBJECT(payload.subjectId));
    message.success(`Thêm nhóm bài học thành công!`);
  } catch (e: any) {
    yield put(COURSE.COURSE_STATE({ error: true }));
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ error: false }));
  }
}

function* onDeleteGroup({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqDelGroup, payload);
    message.success(`Xoá nhóm bài học thành công!`);
    history.goBack();
  } catch (e: any) {
    yield put(COURSE.COURSE_STATE({ error: true }));
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ error: false }));
  }
}

function* onUpdateLesson({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    const { lessonGroupId, ...lesson } = payload;
    yield call(reqUpdateLesson, lesson);
    yield put(COURSE.GET_LESSON_GROUP(lessonGroupId));
    message.success(`Cập nhật bài học thành công!`);
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));
  }
}

function* onAddLesson({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    yield call(reqAddLesson, payload);
    yield put(COURSE.GET_LESSON_GROUP(payload.lessonGroupId));
    yield message.success(`Thêm bài học thành công!`);
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));

  }
}

function* onDeleteLesson({ payload }: ActionType) {
  try {
    yield put(COURSE.COURSE_STATE({ acLoad: true, error: false }));
    const { groupId, lessonId } = payload;
    yield call(reqDeleteLesson, { lessonId });
    yield put(COURSE.GET_LESSON_GROUP(groupId));
    message.success(`Xóa bài học thành công!`);
  } catch (e: any) {
    yield message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ acLoad: false, error: true }));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(COURSE_GET_LIST, getCourseList),
    takeEvery(COURSE_GET_DETAIL, getCourseDetail),
    takeEvery(COURSE_GROUP_DETAIL, getLessonGroup),
    takeLatest(COURSE_ADD_SUBJECT, onAddSubject),
    takeLatest(COURSE_ADD_SUBJECT_CACHE, onAddSubjectCache),
    takeLatest(COURSE_UPDATE_SUBJECT, onUpdateSubject),
    takeLatest(COURSE_DELETE_SUBJECT, onDelSubject),
    takeLatest(COURSE_GET_SUBJECT, onGetSubject),
    takeLatest(COURSE_ADD, onAddCourse),
    takeLatest(COURSE_UPDATE, onUpdateCourse),
    takeLatest(COURSE_DELETE, onDeleteCourse),
    takeLatest(COURSE_UPDATE_LESSON, onUpdateLesson),
    takeLatest(COURSE_UPDATE_GROUP, onUpdateGroup),
    takeLatest(COURSE_DEL_GROUP, onDeleteGroup),
    takeLatest(COURSE_ADD_LESSON, onAddLesson),
    takeLatest(COURSE_DEL_LESSON, onDeleteLesson),
    takeLatest(COURSE_ADD_GROUP, onAddGroup)
  ]);
}
