import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/store';
import App from './containers';
import { Route, Switch } from 'react-router';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

const store = configureStore(/ provide initial state if any /);

const NextApp = () => {
  return <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>;
};
export default NextApp;
library.add( fas)
