import React, { FC, Suspense, memo, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useRouteMatch, useLocation, Switch, Route, RouteProps } from 'react-router';
import MainApp from './MainApp';
import AppLocale from '../lngProvider';
import { AppState } from '../redux/reducers';
import LoadingHint from '../pages/components/LoadingHint';
import { STUDENT } from 'src/redux/actions/actionTypes';
import ChatGPT from '../pages/components/ChatGPT';

interface RestrictedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  token: string | null;
  useRole: string | null;
}

const RestrictedRoute: FC<RestrictedRouteProps> = ({ component: Component, location, token, ...rest }) => {
  return <Route
    {...rest}
    render={props =>
      token
        ? <Component userRole={rest.useRole} {...props} />
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />}
  />;
};

const LoginComponent = React.lazy(() => import('../pages/auth/Login'));
const App = () => {

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { locale } = useSelector((app: AppState) => app.common);
  const { initURL } = useSelector((app: AppState) => app.auth);
  const token = localStorage.getItem('auth');
  const typeLogin = localStorage.getItem('typeLogin')
  const currentAppLocale = AppLocale[locale];
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/') {
      if (token === null) {
        history.push('/login');
      } else {

        if (initURL === '' || initURL === '/' || initURL === '/login') {
          history.push('/');
        } else {
          history.push(initURL);
        }
      }
    }
    if (token !== null) {
      console.log('typeLogin', typeLogin);

      if (typeLogin == 'student') {
        dispatch(STUDENT.GET_PERSONAL_NOTES())
      }
    }
  }, [history, initURL, location.pathname]);

  return <ConfigProvider locale={currentAppLocale.antd}>
    <IntlProvider
      locale={currentAppLocale.antd.locale}
      messages={currentAppLocale.messages}>
      <Suspense fallback={<LoadingHint />}>
        <Switch>
          <Route exact path='/login-admin' component={LoginComponent} />
          <Route exact path='/login-mentor' component={LoginComponent} />
          <Route exact path='/login' component={LoginComponent} />

          <RestrictedRoute
            useRole={typeLogin}
            path={`${match.url}`}
            token={token}
            location={location}
            component={MainApp} />
        </Switch>
      </Suspense>
    </IntlProvider>
    {token && <ChatGPT />}
  </ConfigProvider>;
};
export default memo(App);
