import { STD_STATE } from '../actions/types';
import { ICourseItem } from '../types/Mentor';
import { CollectionNote, IPersonalNotes, IPersonalNoteList } from '../types/User';

interface ICourseList {
  basic: any,
  necessary: any
}

export interface IStdState {
  courseList: ICourseList | null,
  enrolled: ICourseList | null,
  course: ICourseItem | null,
  article: string | null,
  acLoad?: boolean,
  notesList: any;
  collectionNotes: CollectionNote;
  notesDetail: any;
  GPTHistories: any
  GPTMsg: any
  personalNotes:IPersonalNoteList|null;
}

const INITIAL_STATE: IStdState = {
  courseList: null,
  enrolled: null,
  course: null,
  article: null,
  acLoad: false,
  notesList: [],
  collectionNotes: {
    numberOfCollectionNote: 0,
    list: []
  },
  notesDetail: null,
  personalNotes: null,
  GPTHistories: [],
  GPTMsg: []
};

export const studentReducer = (state = INITIAL_STATE, action: any): IStdState => {
  switch (action.type) {
    case STD_STATE: {
      return { ...state, ...action.payload };
    }
    default:
      return { ...state };
  }
};
export default studentReducer;
