import React, { useState, Fragment, useCallback, useEffect, useRef } from 'react';
import { Button, Collapse, ConfigProvider, Drawer, Form, Image, Space, Tooltip } from 'antd';
import { CloseOutlined, FullscreenOutlined, HistoryOutlined } from '@ant-design/icons';
import ChatBox from './ChatBox';
import { Transition } from '@headlessui/react';
import ChatForm from './ChatForm';
import Cookies from 'js-cookie';
import '../../tailwind.scss';
import ChatHistory from './ChatHistory';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { GPT_GET_HISTORIES, GPT_GET_MESSAGES, GPT_SAVE_MESSAGE } from '../../redux/actions/types';

// take note
const API_MODEL = {
  TURBO: 'gpt-3.5-turbo' // create conversation

};
const { Configuration, OpenAIApi } = require('openai');
const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPEN_AI_KEY
});

const openai = new OpenAIApi(configuration);

const ChatGPT = () => {

  const dispatch = useDispatch();
  const { GPTHistories, GPTMsg } = useSelector((app: AppState) => app.student);
  const messageId = localStorage.getItem('CHAT_ID');
  const userId = localStorage.getItem('id');

  const [isShow, setIsShow] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [isFull, setIsFull] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState<any>();

  const [form] = Form.useForm();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<any>(null);

  const scrollBottom = () => {
    contentRef.current?.scrollTo({
      top: contentRef.current?.scrollHeight,
      behavior: 'smooth'
    });
  };
  const onSaveMessage = (message: any) => {
    dispatch({
      type: GPT_SAVE_MESSAGE,
      payload: message
    });
  };

  const onGetMessages = useCallback((messageId: string) => {
    dispatch({
      type: GPT_GET_MESSAGES,
      payload: { messageId, userId }
    });
    setShowHistory(false);
    scrollBottom();
  }, []);

  const onFinish = async ({ message }: any) => {
    try {
      setLoading(true);
      const newQuest = { role: 'user', content: message };
      form.resetFields();
      setConversations((prev: any) => [...prev, newQuest]);
      scrollBottom();
      await onSaveMessage({ ...newQuest, messageId, userId });
      const response = await openai.createChatCompletion({
        model: API_MODEL.TURBO,
        messages: [...conversations, newQuest]
      });
      const { choices } = response.data;
      const reply = choices[0].message;
      await onSaveMessage({
        ...reply,
        messageId,
        userId
      });
      setConversations((prev: any) => [...prev, { ...reply }]);
    } catch (e: any) {
      setConversations((prev: any) => [...prev, {
        role: 'user',
        content: e.message
      }]);
    } finally {
      setLoading(false);
      scrollBottom();
      inputRef.current?.focus();
    }
  };

  const onMinimize = () => {
    setShowHistory(false);
    setIsShow(false);
  };

  const onResend = useCallback((item: any, index: number) => {

  }, []);

  const onGetConversations = async () => {
    return await openai.createChatCompletion({
      model: API_MODEL.TURBO,
      messages: [{ role: 'system', content: 'You are a helpful assistant.' }]
    });
  };

  useEffect(() => {
    messageId && onGetMessages(messageId);
  }, []);

  useEffect(() => {
    setConversations(GPTMsg);
  }, [GPTMsg]);

  useEffect(() => {
    const isFirstCreate = Cookies.get('callBot');
    if (!isFirstCreate) {
      onGetConversations().then((response: any) => {
        const { choices } = response.data;
        const reply = choices[0].message;
        setConversations((prev: any) => [...prev, {
          // id: response.data.id,
          ...reply
        }]);
        Cookies.set('callBot', 'true');
        scrollBottom();
      });
    }
    if (userId && messageId) {
      dispatch({
        type: GPT_GET_HISTORIES,
        payload: { userId, messageId }
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(scrollBottom, 100);
  }, [isShow]);


  return <div className='chat-box'>

    <div className='fixed cursor-pointer  right-5 bottom-5'>
      <Transition
        enter='transition zoom-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition zoom-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
        show={!isShow}>
        <Tooltip title='What is your question?' trigger='hover'>
          <Image
            onClick={() => setIsShow(true)}
            src={'/images/chatbot.png'} width={60} height={60}
            preview={false} />
        </Tooltip>
      </Transition>

    </div>
    <Transition
      as={Fragment}
      enter='transition zoom-out duration-200'
      enterFrom='opacity-0 translate-y-1'
      enterTo='opacity-100 translate-y-0'
      leave='transition zoom-in duration-150'
      leaveFrom='opacity-100 translate-y-0'
      leaveTo='opacity-0 translate-y-1'
      show={isShow}>
      <div className='small-box'>
        <ConfigProvider
          theme={{
            components: {
              Collapse: {
                colorBgContainer: '#f3f4f6'
              }
            }
          }}
        >
          <Collapse
            style={{ backgroundColor: '#FFF', boxShadow: '0 4px 4px 0 rgba(0,0,0,0.5)' }}
            defaultActiveKey='content'>
            <Collapse.Panel
              showArrow={false}
              extra={
                <Space>
                  <Button type='text' onClick={() => {
                    setIsShow(false);
                    setIsFull(true);
                  }}><FullscreenOutlined />
                  </Button>
                  <Button type='text' onClick={(e) => {
                    e.stopPropagation();
                    setShowHistory(true);
                  }}>
                    <HistoryOutlined />
                  </Button>
                  <Button type='text' onClick={onMinimize}>
                    <CloseOutlined />
                  </Button>
                </Space>
              }
              key='content' header={<Space><Image
              src={'/images/chatbot.png'} width={40} height={40}
              preview={false} /> Beva Bot</Space>}>
              <div>
                <ChatBox
                  onLoad={loading}
                  conversations={conversations}
                  showLeft={false}
                  onReSend={onResend}
                  contentRef={contentRef}
                />
                <Drawer
                  keyboard={true}
                  headerStyle={{ flex: 'unset', height: 65 }}
                  contentWrapperStyle={{ borderTopRightRadius: 10, borderBottomRightRadius: 10, overflow: 'hidden' }}
                  bodyStyle={{ padding: 0 }}
                  width={220}
                  onClose={() => setShowHistory(false)}
                  title='Histories'
                  placement='right'
                  open={showHistory}
                  getContainer={false}
                >
                  <ChatHistory
                    onClick={onGetMessages}
                    histories={GPTHistories} />
                </Drawer>
              </div>
              <ChatForm
                inputRef={inputRef}
                onFinish={onFinish}
                form={form}
                loading={loading} />
            </Collapse.Panel>
          </Collapse>
        </ConfigProvider>
      </div>
    </Transition>

    <Drawer
      footer={<ChatForm
        inputRef={inputRef}
        onFinish={onFinish}
        form={form}
        loading={loading} />}
      closable={false}
      title={<Image
        onClick={() => setIsShow(true)}
        src={'/images/chatbot.png'} width={40} height={40}
        preview={false} />}
      extra={<Space>
        <Button onClick={() => setShowHistory(true)} type='text'>
          <HistoryOutlined />
        </Button>
      </Space>}
      bodyStyle={{ backgroundColor: '#f3f4f6' }}
      onClose={() => {
        setIsShow(false);
        setIsFull(false);
      }}
      width={800}
      open={isFull}>
      <div className='max-h-full box-content h-full overflow-hidden'>
        <ChatBox
          onLoad={loading}
          conversations={conversations}
          showLeft={true}
          onReSend={onResend}
          contentRef={contentRef} />
        <Drawer
          onClose={() => setShowHistory(false)}
          title='Histories'
          placement='right'
          open={showHistory}
          bodyStyle={{ padding: 0 }}
        >
          <ChatHistory
            onClick={onGetMessages}
            histories={GPTHistories} />
        </Drawer>
      </div>

    </Drawer>
  </div>;
};
export default ChatGPT;
