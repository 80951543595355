import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  reqEnrollStdRegister,
  reqGetNotesCollection,
  reqGetArticle,
  reqGetStdCourse,
  reqGetStdCourseInfo,
  reqUpdateProcess,
  reqAddNotesCollection,
  reqDeleteNotesCollection,
  reqUpdateNotesCollection,
   reqSaveMessage, reqGetHistories, reqGetMessages
} from '../services/student';
import {
  GPT_GET_HISTORIES, GPT_GET_MESSAGES, GPT_SAVE_MESSAGE,
  NOTES_ADD_COLLECTION,
  NOTES_DELETE_COLLECTION,
  NOTES_GET_COLLECTION,
  NOTES_PERSONAL_GET_LIST,
  NOTES_UPDATE_COLLECTION,
  STD_COURSE_INFO,
  STD_COURSE_LIST,
  STD_ENROLL,
  STD_ENROLL_LIST,
  STD_GET_ARTICLE, STD_STATE,
  STD_UPDATE_PROCESS
} from '../actions/types';
import { ActionType, COMMON, STUDENT } from '../actions/actionTypes';
import { ApiResponse } from '../../utils/AxiosHelper';
import { ECourseType, ICourseResponse, ILessonGroupItem } from '../types/Mentor';
import { message } from 'antd';
import { history } from '../store';
import { ICollectionNote, IResponseAddCollectionNote, IResponseDeleteCollectionNote, IResponsePersonalNote } from '../types/User';
import { globalIntl } from 'src/utils/hepler';
import { reqGetPersonalNotes } from '../services/notes';

function* onGetCourseList({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<ICourseResponse> = yield call(reqGetStdCourse, payload);
    const { listCourse } = response.data;
    if (listCourse) {
      const basic = listCourse.filter((course) => course.typeCourse === ECourseType.basic);
      const necessary = listCourse.filter((course) => course.typeCourse === ECourseType.necessary);

      yield put(STUDENT.SET_STATE({
        enrolled: {
          basic: basic.filter((item) => item.enrolled),
          necessary: necessary.filter((item) => item.enrolled)
        },
        courseList: { basic, necessary }
      }));
    }
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onGetCourseInfo({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<any> = yield call(reqGetStdCourseInfo, payload);
    yield put(STUDENT.SET_STATE({
      course: response.data
    }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onGetEnrolled({ payload }: any) {
  try {
    const response: ApiResponse<any> = yield call(reqGetStdCourseInfo, payload);

  } catch (e) {

  } finally {

  }
}

function* onRegEnroll({ payload }: any) {
  try {
    yield call(reqEnrollStdRegister, { courseId: payload.courseId });
    yield put(STUDENT.GET_COURSE_INFO({ courseId: payload.courseId }));
    history.push({
      ...payload.nextRoute
    });
    message.success('Đăng ký chuyên ngành thành công!');
  } catch (e) {
    message.error(e.message);
  } finally {
  }
}

function* onReqUpdateProcess({ payload }: any) {
  try {
    const { nextGroup, ...processData } = payload;
    yield put(STUDENT.SET_STATE({ acLoad: false }));
    if (nextGroup?.currentUpdate) {
      yield call(reqUpdateProcess, {
        lessonId: processData.processData.lessonId,
        status: 1,
        metaData: { answerQuiz: [] }
      });
    }
    if (!nextGroup?.onlyNext) {
      yield call(reqUpdateProcess, { ...processData.processData });
    }
    if (nextGroup?.nextUpdate && nextGroup?.nextLessonId) {
      yield call(reqUpdateProcess, { lessonId: nextGroup.nextLessonId, status: 1, metaData: { answerQuiz: [] } });
    }
    if (nextGroup?.isFinal) {
      if (nextGroup?.groupId) {
        window.location.replace(`/article/${nextGroup.courseId}/${nextGroup.subjectId}/${nextGroup.groupId}?current=${nextGroup.nextLessonId}`);
      } else {
        window.location.replace(`/course/${nextGroup.courseId}`);
      }
      return;
    }
    history.push({
      pathname: history.location.pathname,
      search: `?current=${nextGroup?.nextLessonId}`
    });
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(STUDENT.SET_STATE({ acLoad: false }));
  }
}

function* onGetArticle({ payload }: any) {
  try {
    const response: ApiResponse<any> = yield call(reqGetArticle, payload);
    yield put(STUDENT.SET_STATE({
      article: response.data.data.text
    }));
  } catch (e) {
    message.error(e.message);
  }
}

function* getCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<ICollectionNote> = yield call(reqGetNotesCollection);
    yield put(STUDENT.SET_STATE({
      collectionNotes: response.data.data
    }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* addCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<IResponseAddCollectionNote> = yield call(reqAddNotesCollection, payload);
    console.log('addCollectionNotes', response.data);
    const msg = globalIntl('std.create.notesCollection.success');
    message.success(msg);
    yield put(STUDENT.GET_NOTES_COLLECTION());
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* deleteCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<IResponseDeleteCollectionNote> = yield call(reqDeleteNotesCollection, payload);
    const msg = globalIntl('std.delete.notesCollection.success');
    message.success(msg);
    yield put(STUDENT.GET_NOTES_COLLECTION());
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* updateCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<{}> = yield call(reqUpdateNotesCollection, payload);
    const msg = globalIntl('std.update.notesCollection.success');
    message.success(msg);
    yield put(STUDENT.GET_NOTES_COLLECTION());
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* getPersonalNotes() {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<IResponsePersonalNote> = yield call(reqGetPersonalNotes)
    console.log('getPersonalNotes', response);

    yield put(STUDENT.SET_STATE({
      personalNotes: response.data.data.list
    }));
    yield put(COMMON.LOADING(false));

  } catch (e) {
    message.error(e.message);
    yield put(COMMON.LOADING(false));
  }
}

function* getMessages({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqGetMessages, payload);
    yield put({
      type: STD_STATE,
      payload: {
        GPTMsg: response.data.data ? response.data.data.map((item: any) => {
          return {
            role: item.role,
            content: item.content
          };
        }) : []
      }
    });
  } catch (e) {
    message.error(e.message);
  }
}

function* getHistories({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqGetHistories, payload);
    yield put({
      type: STD_STATE,
      payload: {
        GPTHistories: response.data.data
      }
    });
  } catch (e) {
    message.error(e.message);
  }
}

function* saveMessage({ payload }: ActionType) {
  try {
    yield call(reqSaveMessage, payload);
  } catch (e) {
    console.log('save message error', e);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(STD_COURSE_LIST, onGetCourseList),
    takeLatest(STD_COURSE_INFO, onGetCourseInfo),
    takeLatest(STD_ENROLL_LIST, onGetEnrolled),
    takeLatest(STD_ENROLL, onRegEnroll),
    takeLatest(STD_GET_ARTICLE, onGetArticle),
    takeLatest(STD_UPDATE_PROCESS, onReqUpdateProcess),
    takeEvery(NOTES_GET_COLLECTION, getCollectionNotes),
    takeLatest(NOTES_ADD_COLLECTION, addCollectionNotes),
    takeLatest(NOTES_DELETE_COLLECTION, deleteCollectionNotes),
    takeLatest(NOTES_UPDATE_COLLECTION, updateCollectionNotes),
    takeLatest(NOTES_PERSONAL_GET_LIST, getPersonalNotes),
    takeLatest(GPT_GET_HISTORIES, getHistories),
    takeLatest(GPT_GET_MESSAGES, getMessages),
    takeLatest(GPT_SAVE_MESSAGE, saveMessage)
  ]);
}
