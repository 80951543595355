import React, { FC, useEffect, useMemo, useState } from 'react';
import { Layout, Image, Spin, Menu, MenuProps } from 'antd';
import AppRoute from '../redux/route';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../redux/reducers';
import { Link } from 'react-router-dom';
import { AUTH } from '../redux/actions/actionTypes';
import { useHistory, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, IconLookup, IconName, findIconDefinition } from '@fortawesome/fontawesome-svg-core';

interface MainProps {
  path: string;
}

interface IMenuRoute {
  title: string;
  key: string;
  path: string;
  icon?: IconName,
  items?: IMenuRoute[];
  fullWith?: boolean;
  hide?: boolean;
}

type MenuItem = Required<MenuProps>['items'][number];


const { Sider, Content } = Layout;

const MainApp: FC<MainProps> = ({ path, ...props }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const coffeeLookup: IconLookup = { prefix: 'fas', iconName: 'location-dot' };
  const coffeeIconDefinition: IconDefinition = findIconDefinition(coffeeLookup);
  const { personalNotes } = useSelector((app: AppState) => app.student);
  console.log('alpha personalNotes', personalNotes);

  function getIconDefinition(iconName: IconName): IconDefinition | undefined {
    const iconLookup: IconLookup = { prefix: 'fas', iconName };
    return findIconDefinition(iconLookup);
  }

  const [collapsed] = useState(false);
  const [menuSelectedKeys, setMenuSelectedKeys] = useState(['overview']);
  const { loading, menus } = useSelector((app: AppState) => app.common);
  const [fullWith, setFullWith] = useState<IMenuRoute[]>([]);
  const typeLogin = localStorage.getItem('typeLogin');
  const [Imenus, setImenus] = useState<IMenuRoute[]>(menus);
  useEffect(() => {
    if (personalNotes) {
      const IpersonalNotes: IMenuRoute[] = [
        {
          title: 'Personal notes',
          key: 'personal-notes',
          path: '/personal-notes/overview',
          icon: 'list-check',
          items: personalNotes.map((note: any) => ({
            title: note.title,
            key: `personal-notes-${note.id}`,
            path: `/personal-notes/${note.id}`
          }))
        }
      ];
      const menusPersonal = menus.concat(IpersonalNotes);

      setImenus(menusPersonal);

    }
  }, [menus, personalNotes]);


  function getLoginType(str: string | null): string {
    if (str!.includes('admin')) {
      return '/login-admin';

    }
    if (str!.includes('mentor')) {
      return '/login-mentor';
    }
    if (str!.includes('/student')) {
      return '/login';

    }
    return '';
  }

  const pathToLogin = getLoginType(typeLogin);

  const config = (items: IMenuRoute[]): React.ReactNode[] => {
    return items.map((menu: IMenuRoute) => {
      const { title, key, path, icon, items: subItems, hide } = menu;
      if (hide) {
        return null; // skip rendering the menu item
      }
      if (subItems) {
        const subMenuItems = config(subItems);
        if (subMenuItems.length) {
          return (
            <Menu.SubMenu key={key} onTitleClick={() => history.push(path)} title={title}
                          icon={<FontAwesomeIcon icon={getIconDefinition(icon!)!} />}>
              {subMenuItems}
            </Menu.SubMenu>
          );
        } else {
          return (
            <Menu.Item key={key} icon={<FontAwesomeIcon icon={getIconDefinition(icon!)!} />}>
              <Link to={path}>{title}</Link>
            </Menu.Item>
          );
        }
      }
      return (
        <Menu.Item key={key} icon={<FontAwesomeIcon icon={getIconDefinition(icon!)!} />}>
          <Link to={path}>{title}</Link>
        </Menu.Item>
      );
    });
  };

  const findFullWithItems = (arr: IMenuRoute[]): IMenuRoute[] => {
    let result: IMenuRoute[] = [];
    arr.forEach((item: IMenuRoute) => {
      if (item.fullWith) {
        result.push(item);
      }
      if (item.items) {
        result = result.concat(findFullWithItems(item.items));
      }
    });
    return result;
  };

  useEffect(() => {
    if (Imenus) {
      const isFull = findFullWithItems(Imenus);
      setFullWith(isFull);
    }
  }, [Imenus]);

  const isFullWidth = useMemo(() => {
    if (!fullWith || !fullWith.length) {
      return false;
    }

    return fullWith && fullWith.findIndex((item: IMenuRoute) => {
      const itemPath = item.path.replace(/:[a-z0-9_\/]+/gi, '');
      return item.fullWith && (location.pathname.includes(itemPath) || item.path === location.pathname);
    }) > -1;
  }, [fullWith, location]);
  const items = config(Imenus);

  return <Layout className='main-layout'>
    <Sider
      style={{ display: isFullWidth ? 'none' : 'block' }}
      collapsible
      trigger={<div onClick={() => dispatch(AUTH.LOGOUT())}>
        <span className='mr-2'>Logout</span>
        <FontAwesomeIcon icon='arrow-right-from-bracket' />
        </div>}
      collapsed={collapsed}>
      <div className='logo'>
        <Link to='/'>
          <Image preview={false} src={collapsed ? '/assets/img/favicon-white.svg' : '/assets/img/Logo.png'} />
        </Link>
      </div>
      <Menu
        onSelect={({ selectedKeys }) => setMenuSelectedKeys(selectedKeys)}
        defaultOpenKeys={['personal-notes']}
        selectable={false}
        theme='dark' mode='inline' selectedKeys={menuSelectedKeys}>
        {items}
      </Menu>
    </Sider>
    <Layout className='site-layout'>
      <Spin spinning={loading}>
        <Content className={!isFullWidth ? 'content-page' : ''}>
          <AppRoute path={path} />
        </Content>
      </Spin>
    </Layout>
  </Layout>;
};
export default MainApp;
