import AppLocale from '../lngProvider';
import { history, initStore } from '../redux/store';
import { ELessonType } from '../redux/types/Mentor';
import CryptoJS from 'crypto-js';

export const PlaceholderUser = '/assets/img/PlaceholderUser.png';
export const getYoutubeId = (youtubeLink: string, checkType: boolean = false) => {
  const youtubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})(?:\S+)?$/;
  const match = youtubeLink.match(youtubeRegex);
  if (match && match[1]) {
    return checkType ? true : match[1];
  }
  return undefined;
};

export const globalIntl = (id: string): string => {
  return AppLocale[initStore.getState().common.locale].messages[id];
};

export const toTiming = (value?: number) => {
  if (!value) {
    return '00:00';
  }
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.floor(value % 60);
  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');
  if (hours <= 0) {
    return `${paddedMinutes}:${paddedSeconds}`;
  }
  return `${hours}:${paddedMinutes}:${paddedSeconds}`;
};

export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getLessonType = (type: number | string) => {
  if (type === ELessonType.video) {
    return 'Video';
  }
  if (type === ELessonType.quiz) {
    return 'Quiz';
  }
  return 'Article';
};
export const isLoginPage = () => {
  const hashToken = localStorage.getItem('auth');
  return !hashToken && ['/', '/login', '/login-admin', '/login-mentor'].includes(window.location.pathname) ||
    history.location.pathname.match(/login/i) ||
    window.location.pathname.match(/login/i);
};
export const findIdByUrl = (data: any, url: string): string | null => {
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    const documents = obj.documents;
    for (let j = 0; j < documents.length; j++) {
      const document = documents[j];
      if (document.url === url) {
        return document.id;
      }
      const children = document.children;
      if (children && children.length > 0) {
        const result = findIdInChildren(children, url);
        if (result) {
          return result;
        }
      }
    }
  }
  return null;
};
export const findIdInChildren = (children: Document[], url: string): string | null => {
  for (let i = 0; i < children.length; i++) {
    const child: any = children[i];
    if (child.url === url) {
      return child.id;
    }
    const grandchildren = child.children;
    if (grandchildren && grandchildren.length > 0) {
      const result = findIdInChildren(grandchildren, url);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const encrypQuiz = (data: any, secretKey = '') => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptQuiz = (cipherText: any, secretKey = '') => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const toObject = (item: any) => {
  if (!item) {
    return {};
  }
  return {
    id: item.id,
    name: item.title,
    group: item.listLessonGrp && item.listLessonGrp.map((group: any) => {
      return {
        id: group.id,
        name: group.title,
        description: group.description,
        percent: group.progressLessonGrpInfo?.progress ?? 0,
        lessons: group.listLessons
      };
    })
  };
};
